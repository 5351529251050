import React from "react";
import DataTable from 'react-data-table-component';
import { tableCustomStyles } from "../../../../Components/Common/Utility";
import { customTableStyles } from '../../../Utility/CustomStylesForReact';


const columns = [
  {
    name: 'Resource #',
    selector: row => row.resourceNumber,
    sortable: true,
  },
  {
    name: 'Incidents',
    selector: row => row.incidents,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Resource Type',
    selector: row => row.resourceType,
    sortable: true,
  },
  {
    name: 'Status',
    selector: row => row.status,
    sortable: true,
  },
  {
    name: 'Status Date & Time',
    selector: row => row.statusDateTime,
    sortable: true,
    format: row => new Date(row.statusDateTime).toLocaleString(),
  },
  {
    name: 'Location',
    selector: row => row.location,
    sortable: true,
  },
  {
    name: 'Zone',
    selector: row => row.zone,
    sortable: true,
  },
  {
    name: 'Area of Responsibility',
    selector: row => row.areaOfResponsibility,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Disposition Code',
    selector: row => row.dispositionCode,
    sortable: true,
  },
];

const data = [
  {
    resourceNumber: '12345',
    incidents: 'Incident A, Incident B',
    resourceType: 'Type X',
    status: 'Active',
    statusDateTime: '2024-07-24T10:30:00Z',
    location: 'Building A',
    zone: 'Zone 1',
    areaOfResponsibility: 'Area A',
    dispositionCode: 'D1',
  },
  {
    resourceNumber: '67890',
    incidents: 'Incident C',
    resourceType: 'Type Y',
    status: 'Inactive',
    statusDateTime: '2024-07-24T11:00:00Z',
    location: 'Building B',
    zone: 'Zone 2',
    areaOfResponsibility: 'Area B',
    dispositionCode: 'D2',
  },
  {
    resourceNumber: '54321',
    incidents: 'Incident D, Incident E',
    resourceType: 'Type Z',
    status: 'Pending',
    statusDateTime: '2024-07-24T11:30:00Z',
    location: 'Building C',
    zone: 'Zone 3',
    areaOfResponsibility: 'Area C',
    dispositionCode: 'D3',
  },
  // Add more data objects as needed
];
const ResourceHistoryTabSection = () => {
  return (
    <>
      <div>
        <DataTable
          dense
          columns={columns}
          data={[]}
          persistTableHead={true}
          customStyles={tableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
        // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default ResourceHistoryTabSection;
