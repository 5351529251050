import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CadDashboardTableCustomStyles, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import { useNavigate } from 'react-router-dom';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { IncidentContext } from '../../../CADContext/Incident';


const IncidentTableSection = ({ isIncidentDispatch }) => {
  const { incidentData } = useContext(IncidentContext);
  const [editValue, setEditValue] = useState("");
  const [incidentID, setIncidentID] = useState("");
  const [incidentNumber, setIncidentNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&IncSta=true`);
  }, [incidentID, incidentNumber]);

  const conditionalRowStyles = [
    {
      when: row => row === editValue,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    }
  ];

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 670);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1400);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const columns = [
    {
      name: <p className='text-end' style={{ position: 'absolute', top: '7px' }}>Comments</p>,
      selector: (row) => <>{row?.Comments} </>,
      width: isSmallScreen ? "100px" : undefined,
    },
    {
      name: 'Incident #',
      selector: (row) => <>{row?.CADIncidentNumber} </>,
      sortable: true,
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'Receive Date & Time',
      selector: (row) => <>{row?.ReportedDate ? getShowingYearMonthDate(row?.ReportedDate) : ""} </>,
      sortable: true,
      width: isSmallScreen ? "160px" : undefined,
    },
    {
      name: 'Source',
      selector: (row) => <>{row?.Source ? row?.Source : ''}</>,
      sortable: true,
      // width: "12%"
      width: isSmallScreen ? "85px" : undefined,
    },
    {
      name: 'Agency Code',
      selector: (row) => <>{row?.AgencyCode} </>,
      sortable: true,
      width: isSmallScreen ? "100px" : undefined,

    },
    {
      name: 'CFS Code',
      selector: (row) => <>{row?.CFSCODE} </>,
      sortable: true,
      width: isSmallScreen ? "100px" : undefined,

    },
    {
      name: 'CFS Description',
      selector: (row) => <>{row?.CFSCodeDescription} </>,
      sortable: true,
      width: isSmallScreen ? "100px" : undefined,
    },
    {
      name: 'Priority',
      selector: (row) => <>{row?.PriorityCode} </>,
      sortable: true,
      width: isSmallScreen ? "90px" : undefined,
    },
    {
      name: 'Resources',
      selector: (row) => <>{row?.Resources} </>,
      sortable: true,
      width: isSmallScreen ? "105px" : undefined,
    },
    {
      name: 'E Timer',
      selector: (row) => row.ReportedDate ? <DateTimeCounter data={row.ReportedDate} /> : "",
      sortable: true,
      width: isSmallScreen ? "90px" : undefined,
    },
    {
      name: 'Location',
      selector: (row) => <>{row?.CrimeLocation ? row?.CrimeLocation : ''}</>,
      sortable: true,
      width: isSmallScreen ? "170px" : undefined,
    }
  ]

  return (
    <>
      <div className='table-container'>
        <div className='header-Container d-flex justify-content-between align-items-center'>
          <span>{'Incidents View'}</span>
          <div className='d-flex' style={{fontSize: "12px", }}>
            <div className='d-flex align-content-center justify-content-start table-header-status' style={{color: "white", }}>
              <div className='d-flex'>
                <span>Unassigned</span>
                <span>5</span>
              </div>
              <div className='d-flex'>
                <span>Active</span>
                <span>0</span>
              </div>
            </div>
            <div className='d-flex align-content-center justify-content-end table-header-buttons'>
              <button>All</button>
              <button>Assigned</button>
              <button>Unassigned</button>
              <button>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px', opacity: 0.7 }} />
                Filters
              </button>
            </div>
          </div>
        </div>
        <DataTable
          dense
          columns={columns}
          data={incidentData}
          onRowClicked={(row) => {
            setIncidentID(row?.IncidentID)
            setIncidentNumber(row?.CADIncidentNumber)
            setEditValue(row);
          }}
          customStyles={CadDashboardTableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          responsive
          striped
          highlightOnHover
          fixedHeader
          persistTableHead={true}
        />

      </div>
    </>
  );
};

export default IncidentTableSection;