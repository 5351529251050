import React, { memo } from 'react';
import Property_Tabs from '../../../../Components/Pages/Property/Property_Tabs';

const PropertyTabSectionModal = (props) => {
    const { openPropertyTabSectionModal, setOpenPropertyTabSectionModal } = props;

    const onCloseLocation = () => {
        setOpenPropertyTabSectionModal(false);
    };

    return (
        <>
            {openPropertyTabSectionModal && (
                <div
                    className="modal-overlay"
                    style={{
                        background: "rgba(0, 0, 0, 0.5)",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        zIndex: 200,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }}
                >
                    <div className="modal-dialog modal-dialog-centered modal-xl">
                        <div className="modal-content modal-content-cad" >
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-12 p-0 ml-2">
                                        <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                                            <p
                                                className="p-0 m-0 font-weight-medium"
                                                style={{
                                                    fontSize: 18,
                                                    fontWeight: 500,
                                                    letterSpacing: 0.5,
                                                }}
                                            >
                                                {'Property'}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                {/* Property Tab */}
                                <Property_Tabs isCad />

                                <div className="row">
                                    <div className="col-12 p-0">
                                        <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                                            <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                                                <button
                                                    type="button"
                                                    className="cancel-button"
                                                    onClick={onCloseLocation}
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default memo(PropertyTabSectionModal);
