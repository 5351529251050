import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { colorLessStyle_Select } from '../../../Utility/CustomStylesForReact';
import MapComponent from "../../../MapComponent";
import { useLocation } from "react-router-dom";
import MonitorServices from '../../../../CADServices/APIs/monitor'
import CallTakerServices from '../../../../CADServices/APIs/callTaker'
import MasterTableListServices from '../../../../CADServices/APIs/masterTableList'
import { base64ToString } from "../../../../Components/Common/Utility";
import { useQuery } from 'react-query';
import useObjState from "../../../../CADHook/useObjState";
import { dropDownDataModel } from "../../../../CADUtils/functions/common";
import { useSelector } from "react-redux";
import Location from "../../../Common/Location";
import { AgencyContext } from "../../../../Context/Agency/Index";

const IncidentTabSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const { setAgencyName, agnecyName } =
    useContext(AgencyContext);
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [locationStatus, setLocationStatus] = useState(false);
  const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
  const [CFSDropDown, setCFSDropDown] = useState([]);
  // const [primaryOfficerDropDown, setPrimaryOfficerDropDown] = useState([])
  const [userName, setUserName] = useState("");
  console.log("CFSDropDown", CFSDropDown);
  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID);
      setAgencyName(localStoreData?.Agency_Name);
      setUserName(localStoreData?.UserName);
    }
  }, [localStoreData]);

  const [data, setData] = useState();
  const [
    incidentState,
    setIncidentState,
    handleIncidentState,
    clearIncidentState,
  ] = useObjState({
    IncidentId: "",
    MasterIncident: "",
    Case: "",
    ReportedDateTime: "",
    OccurredDateTime: "",
    ReceiveSource: "",
    ReceiveSource1: "",
    PrimaryOfficer: "",
    SecondaryOfficer: "",
    CommonPlace: "",
    intersection1: "",
    intersection2: "",
    CFSLId: "",
    CFSLDesc: "",
    CFSLPriority: "",
    location: "",
    ApartmentNo: "",
    Latitude: "",
    Longitude: "",
  })
  const useRouteQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };
  const query = useRouteQuery();
  console.log("incidentState", incidentState);

  let IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));

  const getSingleIncidentKey = `/CAD/Monitor/MonitorIncidentByID`;
  const { data: singleIncidentData, isSuccess: isFetchSingleIncidentData } = useQuery(
    [getSingleIncidentKey, {
      IncidentID: IncID, AgencyID: loginAgencyID,
    }],
    MonitorServices.getSingleIncident,
    {
      refetchOnWindowFocus: false,
      enabled: !!IncID && !!loginAgencyID,
      onSuccess: (data) => {
        const parsedData = JSON.parse(data?.data?.data)?.Table || [];
        setData(parsedData);
        setIncidentState({
          IncidentId: parsedData[0]?.CADIncidentNumber,
          MasterIncident: parsedData[0]?.MasterIncidentNumber,
          Case: "",
          ReportedDateTime: parsedData[0]?.ReportedDate,
          OccurredDateTime: parsedData[0]?.OccurredFrom,
          ReceiveSource: parsedData[0]?.Source,
          CommonPlace: parsedData[0]?.CommonPlace,
          CFSLPriority: prioritiesDropDown?.find((item) => parsedData[0]?.PriorityCode === item?.label),
          PrimaryOfficer: parsedData[0]?.Officer1,
          SecondaryOfficer: parsedData[0]?.Officer2,
          intersection1: "",
          intersection2: "",
          CFSLId: parsedData[0]?.CFSCODE,
          CFSLDesc: parsedData[0]?.CFSCODE,
          location: parsedData[0]?.CrimeLocation,
          Longitude:parsedData[0]?.Longitude,
          Latitude:parsedData[0]?.Latitude,
        })
      },
    }
  );

  const GetPrimaryOfficerKey = `CAD/MasterPrimaryOfficer/PrimaryOfficer`;
  const { data: getPrimaryOfficerData, isSuccess: isFetchPrimaryOfficers } = useQuery(
    [GetPrimaryOfficerKey, {},],
    MasterTableListServices.getPrimaryOfficer,
    {
      refetchOnWindowFocus: false,
    }
  );

  const priorityDataKey = `/CAD/Priority/CallTrackerPriority`;
  const { data: prioritiesData, isSuccess: isFetchPrioritiesData } = useQuery(
    [priorityDataKey, { AgencyID: loginAgencyID },],
    CallTakerServices.getCallTrackerPriority,
    {
      refetchOnWindowFocus: false,
    }
  );

  const CFSCodeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: CFSCodeData, isSuccess: isFetchCFSCodeData } = useQuery(
    [
      CFSCodeKey,
      {
        Action: "GET",
        isActive: 1
      }
    ],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
    }
  );

  const CFSDropDownDataModel = (data, value, label) => {
    const result = data?.map((item) => ({
      value: item[value],
      label: `${item[value]} | ${item[label]}`,
      labelSecond: item[label],
    }));
    return result;
  };

  useEffect(() => {
    if (isFetchPrioritiesData && prioritiesData) {
      const data = JSON.parse(prioritiesData?.data?.data);
      setPrioritiesDropDown(
        dropDownDataModel(data?.Table, "PriorityID", "PriorityCode")
      );
    }
  }, [isFetchPrioritiesData, prioritiesData]);

  useEffect(() => {
    if (isFetchCFSCodeData && CFSCodeData) {
      const parsedData = JSON.parse(CFSCodeData?.data?.data);
      setCFSDropDown(
        CFSDropDownDataModel(parsedData?.Table, "CFSCODE", "CFSCodeDescription")
      );
    }
  }, [isFetchCFSCodeData, CFSCodeData]);

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
  };

  return (
    <>
      <div className="tab-form-monitor-container">
        <div className="d-flex">
          <div
            className="col-8"
            style={{ display: "grid", gap: "5px" }}
          >
            {/* Line 21*/}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Incident #
                </label>
              </div>
              <div className="col-10 d-flex align-items-center justify-content-end" style={{ display: "grid", gap: "5px" }}>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  placeholder="Placeholder"
                  value={incidentState.IncidentId}
                  onChange={(v) => handleIncidentState("IncidentId", v.target.value)}
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap">
                  Master Incident #
                </label>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  placeholder="Placeholder"
                  value={incidentState.MasterIncident}
                  onChange={(v) => handleIncidentState("MasterIncident", v.target.value)}
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap">
                  Case #
                </label>
                <input
                  type="text"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  placeholder="Placeholder"
                  value={incidentState.Case}
                  onChange={(v) => handleIncidentState("Case", v.target.value)}
                  readonly=""
                />
              </div>
            </div>

            {/* Line 2 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Reported Date & Time
                </label>
              </div>
              <div className="col-10 d-flex align-items-center justify-content-end" style={{ display: "grid", gap: "5px" }}>
                <input
                  type="date"
                  className="form-control py-1 new-input requiredColor"
                  placeholder="Placeholder"
                  value={incidentState.ReportedDateTime ? incidentState.ReportedDateTime.split('T')[0] : ""}
                  onChange={(v) => handleIncidentState("ReportedDateTime", v.target.value)}
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap ml-3">
                  Occurred DT / TM
                </label>
                <input
                  type="date"
                  className="form-control py-1 new-input highlighter-input-readonly"
                  placeholder="Placeholder"
                  value={incidentState.OccurredDateTime}
                  onChange={(v) => handleIncidentState("OccurredDateTime", v.target.value)}
                  readonly=""
                />
                <label for="" className="tab-form-label text-nowrap">
                  Recv Source
                </label>

                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                  value={incidentState.ReceiveSource}
                  onChange={(v) => handleIncidentState("ReceiveSource", v.target.value)}
                  readOnly
                />
              </div>
            </div>

            {/* Line 3 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Call Taker
                </label>
              </div>
              <div className="col-3 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                  value={userName}
                  readOnly
                />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Common Place
                </label>
              </div>
              <div className="col-5 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                  value={incidentState.CommonPlace}
                  onChange={(v) => handleIncidentState("CommonPlace", v.target.value)}
                  readOnly
                />
              </div>
            </div>

            {/* Line 4 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label text-nowrap">
                  Call Agency
                </label>
              </div>
              <div className="col-3 d-flex align-self-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  placeholder="Placeholder"
                  value={agnecyName}
                  readOnly
                />
              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Intersection St/St
                </label>
              </div>
              <div className="col-5 d-flex align-items-center justify-content-end">
                <input
                  type="text"
                  className="form-control py-1 new-input mr-1"
                  name="intersection1"
                  value={incidentState.intersection1}
                  onChange={(v) => handleIncidentState("intersection1", v.target.value)}
                  readOnly
                />
                {"/"}
                <input
                  type="text"
                  className="form-control ml-1 py-1 new-input"
                  name="intersection2"
                  value={incidentState.intersection2}
                  onChange={(v) => handleIncidentState("intersection2", v.target.value)}
                  readOnly
                />
              </div>
            </div>
            {/* Line 5 */}
            <div className="tab-form-row">
              <div div className="col-2 d-flex align-self-center justify-content-end" >
                <label for="" className="tab-form-label">
                  Primary Officer
                </label>
              </div>
              <div className="col-4 w-100">
                {/* <Select
                  name="PrimaryOfficer"
                  value={incidentState?.PrimaryOfficer}
                  options={primaryOfficerDropDown}
                  getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                  getOptionValue={(v) => v?.PINID}
                  onChange={(v) => handleIncidentState("PrimaryOfficer", v)}
                  placeholder="Select..."
                  styles={customStylesWithOutColor}
                  className="w-100"
                  menuPlacement="top"
                  isClearable
                  onInputChange={(inputValue, actionMeta) => {
                    if (inputValue.length > 12) {
                      return inputValue.slice(0, 12);
                    }
                    return inputValue;
                  }}
                  maxMenuHeight={130}

                /> */}
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  name="PrimaryOfficer"
                  value={incidentState.PrimaryOfficer}
                  onChange={(v) => handleIncidentState("PrimaryOfficer", v.target.value)}
                  readOnly
                />

              </div>
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  Secondary Officer
                </label>
              </div>
              <div className="col-4 w-100">
                {/* <Select
                  name="SecondaryOfficer"
                  value={incidentState?.SecondaryOfficer}
                  options={primaryOfficerDropDown}
                  getOptionLabel={(v) => v?.FirstName + " " + v?.LastName}
                  getOptionValue={(v) => v?.PINID}
                  onChange={(v) => handleIncidentState("SecondaryOfficer", v)}
                  placeholder="Select..."
                  styles={customStylesWithOutColor}
                  className="w-100"
                  menuPlacement="top"
                  isClearable
                  onInputChange={(inputValue, actionMeta) => {
                    if (inputValue.length > 12) {
                      return inputValue.slice(0, 12);
                    }
                    return inputValue;
                  }}
                  maxMenuHeight={130}

                /> */}
                <input
                  type="text"
                  className="form-control py-1 new-input"
                  name="SecondaryOfficer"
                  value={incidentState.SecondaryOfficer}
                  onChange={(v) => handleIncidentState("SecondaryOfficer", v.target.value)}
                  readOnly
                />
              </div>
            </div>
            {/* Line 6 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label htmlFor="" className="tab-form-label" style={{ textAlign: "end", marginRight: "4px" }}>
                  Location
                </label>
              </div>
              <div className="col-7 w-100 inner-input-fullw">
                <Location
                  {...{
                    value: incidentState,
                    setValue: setIncidentState,
                    locationStatus,
                    setLocationStatus,
                  }}
                  col="location"
                  locationID="NameLocationID"
                  check={true}
                  verify={incidentState?.IsVerify}
                  page="Name"
                  isGEO
                />
              </div>
              <div className="col-3 d-flex tab-form-row-gap">
                <div className=" d-flex align-self-center justify-content-end">
                  <label
                    for=""
                    class="tab-form-label text-nowrap"
                  >
                    Apt/Suite#
                  </label>
                </div>
                <input
                  type="number"
                  className="form-control  py-1 new-input"
                  name="ApartmentNo"
                  value={incidentState.ApartmentNo}
                  onChange={(e) => handleIncidentState("ApartmentNo", e.target.value)}
                />
              </div>
            </div>
            {/* Line 7 */}
            <div className="tab-form-row">
              <div className="col-2 d-flex align-self-center justify-content-end">
                <label for="" className="tab-form-label">
                  CFS
                </label>
              </div>
              <div className="col-7 d-flex align-self-center justify-content-end">
                <Select
                  name="CFSLId"
                  value={CFSDropDown.find((opt) => opt.value === incidentState?.CFSLId)}  // Keep the selected value
                  options={CFSDropDown}
                  getOptionLabel={(v) => incidentState?.CFSLId === v?.value ? v?.value : v?.label}  // Show only value after selection
                  getOptionValue={(v) => v?.value}
                  onChange={(v) => {
                    handleIncidentState("CFSLId", v?.value);
                    handleIncidentState("CFSLDesc", v?.value);

                  }}
                  placeholder="Select..."
                  styles={colorLessStyle_Select}
                  className="w-100"
                  menuPlacement="top"
                  isClearable
                  onInputChange={(inputValue, actionMeta) => {
                    if (inputValue.length > 12) {
                      return inputValue.slice(0, 12);
                    }
                    return inputValue;
                  }}
                />
                <div className="col-7 w-100">
                  <Select
                    name="CFSLDesc"
                    value={CFSDropDown.find((opt) => opt.value === incidentState?.CFSLDesc)}  // Keep the selected value
                    options={CFSDropDown}
                    getOptionLabel={(v) => incidentState?.CFSLDesc === v?.value ? v?.labelSecond : v?.label}  // Show only value after selection
                    getOptionValue={(v) => v?.value}
                    onChange={(v) => {
                      handleIncidentState("CFSLDesc", v?.value);
                      handleIncidentState("CFSLId", v?.value);
                    }}
                    placeholder="Select..."
                    styles={colorLessStyle_Select}
                    className="w-100"
                    menuPlacement="top"
                    isClearable
                    onInputChange={(inputValue, actionMeta) => {
                      if (inputValue.length > 12) {
                        return inputValue.slice(0, 12);
                      }
                      return inputValue;
                    }}
                  />
                </div>
              </div>
              <div className="col-3 d-flex align-items-center justify-content-end">
                <label for="" className="tab-form-label mr-2">
                  Priority
                </label>
                <Select
                  name="CFSLPriority"
                  value={incidentState?.CFSLPriority}
                  options={prioritiesDropDown}
                  getOptionLabel={(v) => v?.label}
                  getOptionValue={(v) => v?.value}
                  onChange={(v) => handleIncidentState("CFSLPriority", v)}
                  placeholder="Select..."
                  styles={colorLessStyle_Select}
                  className="w-100"
                  menuPlacement="top"
                  isClearable
                  onInputChange={(inputValue, actionMeta) => {
                    if (inputValue.length > 12) {
                      return inputValue.slice(0, 12);
                    }
                    return inputValue;
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-4" style={{ zIndex: "0" }}>
            <MapComponent latitude={incidentState.Latitude} longitude={incidentState.Longitude} />
          </div>
        </div>

        <div className="tab-form-row from-button-container" >
          <button className="cancel-button">Cancel</button>
          <button className="save-button">Save</button>

        </div>
      </div>
    </>
  );
};

export default IncidentTabSection;
