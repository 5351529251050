import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from 'react-redux';
import Select from "react-select";
import CallTakerServices from "../../CADServices/APIs/callTaker";
import {
  dropDownDataModel,
  handleNumberTextKeyDown,
  handleTextKeyDown,
  isEmpty,
  isEmptyObject,
} from "../../CADUtils/functions/common";
import GeoServices from "../../CADServices/APIs/geo";
import MasterTableListServices from "../../CADServices/APIs/masterTableList";
import Location from "../Common/Location";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import GEOModal from "../GEOModal";
import NameSearchModal from "../NameSearch/NameSearchModal";
import VehicleSearchModal from "../VehicleSearch/VehicleSearchModal";
import ContactInfoModal from "../ContactInfoModal";
import LocationInformationModal from "../LocationInformationModal";
import useObjState from "../../CADHook/useObjState";
import MonitorServices from '../../CADServices/APIs/monitor'
import ModalConfirm from "../Common/ModalConfirm";
import { IncidentContext } from "../../CADContext/Incident";

const CallTakerModal = (props) => {
  const { openCallTakerModal, setCallTakerModal, isIncidentDispatch, setIsIncidentDispatch } = props;
  const mapRef = useRef(null);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyBBfoUsiF8ZX5iopPppB9x5XRriRaALhiY",
  });
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const { incidentRefetch } = useContext(IncidentContext);

  //#region //! useState 
  const [loginAgencyID, setLoginAgencyID] = useState('');
  const [loginPinID, setLoginPinID] = useState(1);
  const [isGoogleLocation, setIsGoogleLocation] = useState(true)
  const [receiveSourceDropDown, setReceiveSourceDropDown] = useState([]);
  const [prioritiesDropDown, setPrioritiesDropDown] = useState([]);
  const [reasonCodeDropDown, setReasonCodeDropDown] = useState([]);
  const [vehiclePlateTypeDropDown, setVehiclePlateTypeDropDown] = useState([]);
  const [resourceDropDown, setResourceDropDown] = useState([]);
  const [tagYearDropDown, setTagYearDropDown] = useState([]);
  const [stateDropDown, setStateDropDown] = useState([]);
  const [premiseDropDown, setPremiseDropDown] = useState([]);
  const [cfsDropDown, setCFSDropDown] = useState([]);
  const [cfsDescDropDown, setCFSDescDropDown] = useState([]);
  const [flagDropDown, setFlagDropDown] = useState([]);
  const [locationStatus, setLocationStatus] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(0);
  const [geoLocationID, setGeoLocationID] = useState();
  const [onSelectLocation, setOnSelectLocation] = useState(true);
  const [geoZoneDropDown, setGeoZoneDropDown] = useState([]);
  const [emsZoneDropDown, setEmsZoneDropDown] = useState([]);
  const [fireZoneDropDown, setFireZoneDropDown] = useState([]);
  const [otherZoneDropDown, setOtherZoneDropDown] = useState([]);
  const [locationData, setLocationData] = useState();
  const [nameId, setNameId] = useState("");
  const [vehicleId, setVehicleID] = useState("");
  const [incidentId, setIncidentId] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [activeIndexLocation, setActiveIndexLocation] = useState([[], []]);
  const [openGEOModal, setOpenGEOModal] = useState(false);
  const [openContactInfoModal, setOpenContactInfoModal] = useState(false);
  const [openLocationInformationModal, setOpenLocationInformationModal] = useState(false);
  const [isSelectLocation, setIsSelectLocation] = useState(false);
  const [CFSID, setCFSID] = useState(null);
  const [selectedButton, setSelectedButton] = useState([]);
  const [zoom, setZoom] = useState(17);
  const [isOpenSearchNameModel, setIsOpenSearchNameModel] = useState(false);
  const [isOpenVehicleSearchModel, setIsOpenVehicleSearchModel] = useState(false);
  const [nameData, setNameData] = useState([]);
  const [isChangeFields, setIsChangeFields] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [
    nameCallTaker,
    setNameCallTaker,
    handleNameCallTaker,
    clearState,
  ] = useObjState({
    FirstName: "",
    LastName: "",
    NameReasonCodeID: [""],
  });

  const [
    errorCallTaker,
    _setErrorCallTaker,
    handleErrorCallTaker,
    clearStateCallTaker,
  ] = useObjState({
    location: false,
    ReceiveSourceID: false,
    CallforServiceID: false,
    CFSLPriority: false,
    patrolZone: false,
    emsZone: false,
    fireZone: false,
    otherZone: false,
    Resource1: false,
  });

  const initialFormValues = {
    PremiseNo: "",
    stDirection: "",
    Street: "",
    stDirection2: "",
    ApartmentNo: "",
    commonPlaceName: "",
    premiseType: null,
    City: "",
    ZipCode: "",
    mileMarker: "",
    coordinateX: "",
    coordinateY: "",
    AltStreet: "",
    intersection1: "",
    intersection2: "",
    verify: false,
    patrolZone: null,
    emsZone: null,
    fireZone: null,
    otherZone: null,
    currentFlag: null,
    location: "",
    IsVerify: true,
    isStreet: false,
    isCity: false,
    isPremiseNo: false,
    isZipCode: false,
    isMileMarker: false,
    isCommonPlaceName: false,
    isStDirection: false,
    isStDirection2: false,
    isIntersection1: false,
    isIntersection2: false,
    isAltStreet: false,
    isApartmentNo: false,
    isCoordinateX: false,
    isCoordinateY: false,
  };

  const [geoFormValues, setGEOFormValues] = useState(initialFormValues);

  const initialValueContact = {
    LastName: "",
    MiddleName: "",
    FirstName: "",
    PhoneType: {},
    PhoneNumber: "",
  };
  const [contactInformation, setContactInformation] =
    useState(initialValueContact);


  const incidentInitialValues = {
    location: "",
    ApartmentNo: "",
    ReceiveSourceID: "",
    CallforServiceID: "",
    CFSLDetails: "",
    CFSLPriority: "",
    CFSL: 1,
    CFSF: 0,
    CFSE: 0,
    OTHER: 0,
    Comments: "",
    Resource1: "",
    Resource2: "",
    Resource3: "",
    Resource4: "",
    Resource5: "",
    Resource6: "",
    Resource7: "",
    Resource8: "",
  };

  const [incidentFormValues, setIncidentFormValues] = useState(
    incidentInitialValues
  );

  const [vehicleData, setVehicleData] = useState([]);
  const [
    vehicleCallTaker,
    setVehicleCallTaker,
    handleVehicleCallTaker,
    clearVehicleState,
  ] = useObjState({
    Plate: "",
    StateCode: null,
    PlateTypeCode: null,
    TagYear: null,
  });

  //#endregion

  const locations = [
    {
      lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
      lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
      status: "open",
      name: geoFormValues?.location,
    },
  ];

  const mapCenter = {
    lng: parseFloat(geoFormValues?.coordinateX) || -98.5795,
    lat: parseFloat(geoFormValues?.coordinateY) || 39.8283,
  };

  //#region //! API

  const receiveSourceKey = `/CAD/CallTakerReceiveSource/GetData_ReceiveSource`;
  const { data: receiveSourceData, isSuccess: isFetchReceiveSourceData } =
    useQuery(
      [receiveSourceKey, { Action: "GetData_ReceiveSource" }],
      CallTakerServices.getReceiveSource,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID
      }
    );
  const CFSCodeKey = `/CAD/MasterCallforServiceCode/InsertCallforServiceCode`;
  const { data: cfsData, isSuccess: isFetchCFSData } = useQuery(
    [
      CFSCodeKey,
      {
        Action: "GET",
        IsActive: 1
      }
    ],
    MasterTableListServices.getCFS,
    {
      refetchOnWindowFocus: false,
    }
  );
  const getNameReasonCodeKey = `/CAD/DispatcherNameReasonCode/GetDataDropDown_NameReasonCode`;
  const payloadReasonCode = { CategoryID: 1 };
  const { data: nameReasonCodeData, isSuccess: isFetchNameReasonCodeData } =
    useQuery(
      [getNameReasonCodeKey, { payloadReasonCode }],
      CallTakerServices.getNameReasonCode,
      {
        refetchOnWindowFocus: false,
      }
    );

  const vehiclePlateTypePayload = {
    PINID: 0,
    IsActive: 1,
    IsSuperAdmin: 1,
    AgencyID: loginAgencyID,
  };

  const vehiclePlateTypeKey = `/CAD/CallTakerVehiclePlateType/GetData_PropertyVehiclePlateType`;
  const { data: vehiclePlateTypeData, isSuccess: isFetchVehiclePlateTypeData } =
    useQuery(
      [
        vehiclePlateTypeKey,
        {
          vehiclePlateTypePayload,
        },
      ],
      CallTakerServices.getPropertyVehiclePlateType,
      {
        refetchOnWindowFocus: false,
        enabled: !!loginAgencyID
      }
    );

  const tagYearKey = `/CAD/CallTakerVehiclePlateType/GetData_TagYear`;
  const { data: tagYearData, isSuccess: isFetchTagYearData } = useQuery(
    [tagYearKey],
    CallTakerServices.getTagYear,
    {
      refetchOnWindowFocus: false,
    }
  );

  const stateKey = `/CAD/CallTakerState/GetData_State`;
  const { data: stateData, isSuccess: isFetchStateData } = useQuery(
    [stateKey],
    CallTakerServices.getState,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getResourcesKey = `/CAD/CallTackerResource/GetData_Resources`;
  const { data: getResourcesData, isSuccess: isFetchResourcesData } = useQuery(
    [getResourcesKey, { AgencyID: loginAgencyID },],
    MonitorServices.getResourceView,
    {
      refetchOnWindowFocus: false,
      enabled: !!loginAgencyID
    }
  );

  const priorityDataKey = `/CAD/Priority/CallTrackerPriority`;
  const { data: priorityData, isSuccess: isFetchPriorityData } = useQuery(
    [priorityDataKey, { AgencyID: loginAgencyID },],
    CallTakerServices.getCallTrackerPriority,
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (isFetchPriorityData && priorityData) {
      const data = JSON.parse(priorityData?.data?.data);
      setPrioritiesDropDown(
        dropDownDataModel(data?.Table, "PriorityID", "PriorityCode")
      );
    }
  }, [isFetchPriorityData, priorityData]);

  const getPremiseKey = `/CAD/GeoPremiseType/GetData_Premise`;
  const { data: premiseData, isSuccess: isFetchPremiseData } = useQuery(
    [getPremiseKey, {}],
    GeoServices.getPremise,
    {
      refetchOnWindowFocus: false,
    }
  );

  const getFlagKey = `/CAD/GeoFlage/GetData_Flag`;
  const { data: flagData, isSuccess: isFetchFlagData } = useQuery(
    [getFlagKey, {}],
    GeoServices.getFlag,
    {
      refetchOnWindowFocus: false,
    }
  );

  const payload = { GeoLocationID: geoLocationID };
  const getContactKey = `/CAD/GeoLocationContactDetail/GetData_GeoContact/${geoLocationID}`;
  const {
    data: contactData,
    isSuccess: isFetchContactData,
  } = useQuery([getContactKey, { payload }], GeoServices.getContactData, {
    refetchOnWindowFocus: false,
    enabled: !!geoLocationID,
  });

  const geoZoneKey = `/CAD/GeoPetrolZone/GetData_Zone`;
  const { data: geoZoneData, isSuccess: isFetchGeoZoneData } = useQuery(
    [geoZoneKey, { IsActive: 1 }],
    GeoServices.getGeoZone,
    {
      refetchOnWindowFocus: false,
    }
  );

  //#endregion

  useEffect(() => {
    if (isFetchGeoZoneData && geoZoneData) {
      const data = JSON.parse(geoZoneData?.data?.data)?.Table || [];
      setGeoZoneDropDown(
        dropDownDataModel(data, "ZoneID", "ZoneDescription")
      );
    }
  }, [isFetchGeoZoneData, geoZoneData]);

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
      setLoginAgencyID(localStoreData?.AgencyID);
    }
  }, [localStoreData]);

  useEffect(() => {
    if (isFetchReceiveSourceData && receiveSourceData) {
      const data = JSON.parse(receiveSourceData?.data?.data);
      setReceiveSourceDropDown(data?.Table || []);
    }
  }, [isFetchReceiveSourceData, receiveSourceData]);

  useEffect(() => {
    if (isFetchNameReasonCodeData && nameReasonCodeData) {
      const data = JSON.parse(nameReasonCodeData?.data?.data);
      setReasonCodeDropDown(
        dropDownDataModel(data?.Table, "NameReasonCodeID", "Description")
      );
    }
  }, [isFetchNameReasonCodeData, nameReasonCodeData]);

  useEffect(() => {
    if (isFetchCFSData && cfsData) {
      const data = JSON.parse(cfsData?.data?.data)?.Table;
      const dropDownDataModel1 = (data, value, label) => {
        const result = data?.map((item) => ({
          value: item[value],
          label: `${item[value]} | ${item[label]}`,
          name: item[label],
        }));
        return result;
      };
      setCFSDropDown(data);
      setCFSDescDropDown(data);
    }
  }, [isFetchCFSData, cfsData]);

  useEffect(() => {
    if (isFetchVehiclePlateTypeData && vehiclePlateTypeData) {
      const data = JSON.parse(vehiclePlateTypeData?.data?.data);
      setVehiclePlateTypeDropDown(
        dropDownDataModel(data?.Table, "PlateTypeCode", "Description")
      );
    }
  }, [isFetchVehiclePlateTypeData, vehiclePlateTypeData]);

  useEffect(() => {
    if (isFetchResourcesData && getResourcesData) {
      const data = JSON.parse(getResourcesData?.data?.data);
      setResourceDropDown(
        dropDownDataModel(data?.Table, "ResourceID", "ResourceNumber")
      );
    }
  }, [isFetchResourcesData, getResourcesData]);

  useEffect(() => {
    if (isFetchPremiseData && premiseData) {
      const data = JSON.parse(premiseData?.data?.data);
      setPremiseDropDown(dropDownDataModel(data?.Table, "ID", "PremiseType"));
    }
  }, [isFetchPremiseData, premiseData]);

  useEffect(() => {
    if (isFetchTagYearData && tagYearData) {
      const data = JSON.parse(tagYearData?.data?.data);
      setTagYearDropDown(dropDownDataModel(data?.Table, "TagYear", "TagYear"));
    }
  }, [isFetchTagYearData, tagYearData]);

  useEffect(() => {
    if (isFetchStateData && stateData) {
      const data = JSON.parse(stateData?.data?.data);
      setStateDropDown(dropDownDataModel(data?.Table, "State", "StateName"));
    }
  }, [isFetchStateData, stateData]);

  useEffect(() => {
    const fetchLocationData = async () => {
      try {
        const response = await GeoServices.getLocationData({
          Location: geoFormValues?.location,
        });
        const data = JSON.parse(response?.data?.data)?.Table || [];
        setLocationData(data);

      } catch (error) {
        console.error("Error fetching location data:", error);
        setLocationData([]);
      }
    };

    if (geoFormValues?.location) {
      fetchLocationData();
    }
  }, [geoFormValues?.location, isSelectLocation]);

  useEffect(() => {
    if (isFetchFlagData && flagData) {
      const data = JSON.parse(flagData?.data?.data);
      setFlagDropDown(dropDownDataModel(data?.Table, "ID", "CurrentFlag"));
    }
  }, [isFetchFlagData, flagData]);

  useEffect(() => {
    if (isFetchContactData && contactData) {
      if (contactData?.data?.Data?.length === 0) {
        return;
      } else {
        try {
          const parsedData = JSON.parse(contactData?.data?.data);
          const contactInfo = parsedData?.Table;

          contactInfo.forEach((item) => {
            if (typeof item.PhoneNo === "string") {
              try {
                item.PhoneNo = JSON.parse(item.PhoneNo);
              } catch (error) {
                console.error("Error parsing PhoneNo:", error);
              }
            }
          });

          setContactList(contactInfo);
        } catch (error) {
          console.error("Error parsing contact data:", error);
        }
      }
    }
  }, [isFetchContactData, contactData]);

  function handleClear() {
    setGeoLocationID();
    clearStateCallTaker();
    setGEOFormValues(initialFormValues);
    setIsSelectLocation(false);
    clearState();
    setIsChangeFields(false);
  }

  const onCloseLocation = () => {
    setCallTakerModal(false);
    clearVehicleState();
    setGeoLocationID("");
    clearStateCallTaker();
    setContactList([]);
    setContactInformation(initialValueContact);
    handleClear();
    setVehicleID("");
    clearState();
    setNameId("");
    setIncidentId([]);
    setIncidentFormValues(incidentInitialValues);
    setGEOFormValues(initialFormValues);
    setActiveIndexLocation([[], []]);
    setIsSelectLocation(false);
    setIsChangeFields(false);
  };

  const handleInputChangeIncident = (e) => {
    const { name, value } = e.target;
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setIsChangeFields(true);
  };

  const handleSelectChangeIncident = (selectedOption, { name }) => {
    if (name === "Resource1" && selectedOption?.length > 8) {
      return; // Prevent further selection if the limit is exceeded
    }
    setIncidentFormValues((prevState) => ({
      ...prevState,
      [name]: selectedOption,
    }));
    setIsChangeFields(true);
  };

  const handleCheckboxChangeIncident = (key) => (e) => {
    setIncidentFormValues((prevValues) => ({
      ...prevValues,
      [key]: e.target.checked ? 1 : 0,
    }));
  };

  const handleSelectChangeIncidentCFS = (selectedOption, { name }) => {
    setIsChangeFields(true);

    const updatedValues = { ...incidentFormValues };

    if (selectedOption === null) {
      if (name === "CallforServiceID") {
        updatedValues.CallforServiceID = null;
        updatedValues.CFSLDetails = null;
      }

      if (name === "CFSLDetails") {
        updatedValues.CFSLDetails = null;
        updatedValues.CallforServiceID = null;
      }

      setIncidentFormValues(() => ({
        ...updatedValues,
        CFSL: 0,
        CFSF: 0,
        CFSE: 0,
        OTHER: 0,
      }));
      return;
    }

    setCFSID(selectedOption?.CallforServiceID);
    const selectedData = JSON.parse(cfsData?.data?.data)?.Table.find(
      (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
    );

    if (name === "CallforServiceID") {
      updatedValues.CallforServiceID = selectedOption;
      const selectedDescription = cfsDescDropDown.find(
        (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
      );
      updatedValues.CFSLDetails = selectedDescription;
    }

    if (name === "CFSLDetails") {
      updatedValues.CFSLDetails = selectedOption;
      const selectedDescription = cfsDropDown.find(
        (item) => item?.CallforServiceID === selectedOption?.CallforServiceID
      );
      updatedValues.CallforServiceID = selectedDescription;
    }

    if (selectedData) {
      setIncidentFormValues(() => ({
        ...updatedValues,
        CFSL: 1,
        CFSF: selectedData?.FIRE ? 1 : 0,
        CFSE: selectedData?.EMERGENCYMEDICALSERVICE ? 1 : 0,
        OTHER: selectedData?.OTHER ? 1 : 0,
      }));
    }
  };


  const handleSelectIncidentName = (selectedOption, { name }) => {
    const data = selectedOption.map(item => item?.value)
    handleNameCallTaker("NameReasonCodeID", data)
    setIsChangeFields(true);
  };

  const handleSelectIncidentVehicle = (selectedOption, { name }) => {
    handleVehicleCallTaker(name, selectedOption)
    setIsChangeFields(true);
  };

  //#region  handler
  //
  const getColorByStatus = (status) => {
    switch (status) {
      case "open":
        return "green";
      case "closed":
        return "red";
      case "pending":
        return "yellow";
      default:
        return "blue";
    }
  };

  const validateGeoFormValues = () => {
    let isError = false;
    const keys = Object.keys(errorCallTaker);
    keys.map((field) => {
      if (
        field === "location" &&
        isEmpty(geoFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "ReceiveSourceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else if (field === "CallforServiceID" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      }
      //  else if (field === "patrolZone" && isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // } else if (field === "emsZone" && isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // } else if (field === "fireZone" && isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // } else if (field === "otherZone" && isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // }
      else if (field === "Resource1" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      }
      else if (field === "CFSLPriority" && isEmptyObject(incidentFormValues[field])) {
        handleErrorCallTaker(field, true);
        isError = true;
      } else {
        handleErrorCallTaker(field, false);
      }
      // if (isEmptyObject(geoFormValues[field])) {
      //   handleErrorCallTaker(field, true);
      //   isError = true;
      // } else {
      //   handleErrorCallTaker(field, false);
      // }
    });
    return !isError;
  };

  const customStylesWithOutColor = {
    control: base => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const customStylesWithOutColorArrow = {
    control: base => ({
      ...base,
      // height: 20,
      minHeight: 35,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? null : "rotate(180deg)"
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const colourStylesEC = {
    control: (styles) => ({
      ...styles,
      backgroundColor: "#fce9bf",
      minHeight: 35,
      height: 20,
      fontSize: 14,
      margintop: 2,
      boxShadow: 0,
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null
    }),
    input: (provided) => ({
      ...provided,
      minWidth: '0px',
      maxWidth: '100%',
    }),
  };

  const addName = async () => {
    setNameData((prevData) => [...prevData, nameCallTaker]);
    // const response = await CallTakerServices.updateName(nameCallTaker);
    clearState();
  };

  const addVehicle = () => {
    setVehicleData((prevData) => [...prevData, vehicleCallTaker]);
    clearVehicleState();
  };
  //#endregion

  const getResourceValues = () => {
    return incidentFormValues?.Resource1?.map(item => item.value).join(',');
  };

  const createFormData = (resourceValues) => {
    const formData = new FormData();
    formData.append('Incident.AgencyID', loginAgencyID || "");
    formData.append('Incident.LocationID', geoFormValues?.Id ? geoFormValues?.Id : geoLocationID || "");
    // formData.append('Incident.DirectionPrefix', geoFormValues?.stDirection || "");
    formData.append('Incident.DirectionPrefix', null || "");
    formData.append('Incident.Street', geoFormValues?.Street || "");
    formData.append('Incident.DirectionSufix', geoFormValues?.stDirection2 || "");
    formData.append('Incident.City', geoFormValues?.City || "");
    formData.append('Incident.ZipCode', geoFormValues?.ZipCode || "");
    formData.append('Incident.PremiseNo', geoFormValues?.PremiseNo || "");
    formData.append('Incident.ApartmentNo', incidentFormValues?.ApartmentNo || "");
    formData.append('Incident.CommonPlace', geoFormValues?.commonPlaceName || "");
    formData.append('Incident.PremiseType', geoFormValues?.premiseType?.value || "");
    formData.append('Incident.MileMaker', geoFormValues?.mileMarker || "");
    formData.append('Incident.AltStreet', geoFormValues?.AltStreet || "");
    formData.append('Incident.InterDirectionPrefix', geoFormValues?.intersection1 || "");
    formData.append('Incident.InterDirectionSufix', geoFormValues?.intersection2 || "");
    formData.append('Incident.CreatedByUserFK', loginPinID || "");
    formData.append('Incident.CFSL', incidentFormValues?.CFSL || 0);
    formData.append('Incident.CFSDetails', incidentFormValues?.CFSLDetails?.CallforServiceID || "");
    formData.append('Incident.CFSF', incidentFormValues?.CFSF || 0);
    formData.append('Incident.Comments', incidentFormValues?.Comments || "");
    formData.append('Incident.CFSE', incidentFormValues?.CFSE || 0);
    formData.append('Incident.OTHER', incidentFormValues?.OTHER || 0);
    formData.append('Incident.CADCFSCodeID', incidentFormValues?.CallforServiceID?.CallforServiceID || "");
    formData.append('Incident.ResourceIDs', resourceValues);
    formData.append('Incident.PriorityID', incidentFormValues?.CFSLPriority?.value || "");
    formData.append('Incident.Location', geoFormValues?.location || "");
    formData.append('Incident.ReceiveSourceID', incidentFormValues?.ReceiveSourceID?.ReceiveSourceID || "");
    formData.append('Incident.Latitude', geoFormValues?.coordinateY || "");
    formData.append('Incident.Longitude', geoFormValues?.coordinateX || "");
    return formData;
  };

  const createLocationPayload = () => {
    const {
      Street = "", stDirection = "", stDirection2 = "", City = "",
      ZipCode = "", PremiseNo = "", ApartmentNo = "", commonPlaceName = "",
      premiseType = {}, coordinateX = "", coordinateY = "", mileMarker = "",
      AltStreet = "", intersection1 = "", intersection2 = "", patrolZone = {},
      emsZone = {}, fireZone = {}, otherZone = {}, IsVerify = "", location = "",
      currentFlag = []
    } = geoFormValues || {};

    return {
      ...(geoLocationID && { ID: geoLocationID }),
      Street, "DirectionPrefix": stDirection, "DirectionSufix": stDirection2,
      City, ZipCode, PremiseNo, ApartmentNo, "CommonPlace": commonPlaceName,
      "PremiseType": premiseType?.label || "", "Latitude": coordinateX, "Longitude": coordinateY,
      "MileMaker": mileMarker, AltStreet, "InterDirectionPrefix": intersection1,
      "InterDirectionSufix": intersection2, "PatrolZone": patrolZone?.label, "EMSZone": emsZone?.label,
      "FireZone": fireZone?.label, "OtherZone": otherZone?.label, IsVerified: IsVerify,
      location, "CurrentFlage": currentFlag?.map(item => item?.label).join(", "),
      "GeoLocationContactsJson": JSON.stringify({ Contacts: contactList || [] }),
      "CreatedByUserFK": loginPinID
    };
  };

  const updateSelectedButton = () => {
    setSelectedButton(prevSelected =>
      prevSelected.includes(2)
        ? prevSelected.filter(item => item !== 2)
        : [...prevSelected, 2]
    );
    onCloseLocation()
  };

  const insertIncident = async (formData) => {
    try {
      const response = await CallTakerServices.insertIncident(formData);
      const data = JSON.parse(response?.data?.data)
      if (data?.Table?.length > 0) {
        setIsIncidentDispatch(!isIncidentDispatch);
        onCloseLocation();
        updateSelectedButton();
      } else {
        console.error("Failed to insert incident data");
      }
    } catch (error) {
      console.error("Failed to insert incident data", error);
    }
  };

  const handleOnSave = async () => {
    if (!validateGeoFormValues()) return;
    const resourceValues = getResourceValues();
    const formData = createFormData(resourceValues);
    if (isGoogleLocation) {
      const locationPayload = createLocationPayload();
      const response = await GeoServices.insertLocation(locationPayload);
      if (response?.data?.success) {
        if (!geoLocationID) {
          const data = JSON.parse(response?.data?.data);
          setGeoLocationID(data?.Table[0]?.GeoLocationID);
        }
        setIsChangeFields(false);
      }
      await insertIncident(formData);
    } else {
      await insertIncident(formData);
    }
    incidentRefetch()
    setNameData([]);
  };

  const onNameSearch = async () => {
    setIsOpenSearchNameModel(true)
  }

  function handleCloseConfirm() {
    setShowConfirmModal(false);
    onCloseLocation();
    setIsChangeFields(false);
  }

  const filterResourcesOptions = (resourceName) => {
    const selectedValues = Object.values(incidentFormValues)
      .filter((resource) => resource !== null && resource?.name !== resourceName)
      .map((resource) => resource.value);

    return resourceDropDown.filter((option) => !selectedValues.includes(option.value));
  };

  return (
    <>
      {openCallTakerModal ? (
        <dialog
          className="modal fade"
          style={{
            background: "rgba(0,0,0, 0.5)",
            zIndex: "200",
            overflow: "hidden",
          }}
          id="CallTakerModal"
          tabIndex="-1"
          aria-hidden="true"
          data-backdrop="false"
        >
          <div className="modal-dialog modal-dialog-centered modal-xl">
            <div
              className="modal-content modal-content-cad"
              style={{
                maxHeight: "calc(100vh - 100px)",
                overflowY: "auto",
              }}
            >
              <div className="modal-body">
                {/* Modal Title */}
                <div className="row">
                  <div className="col-12 p-0 pb-2">
                    <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                      <p
                        className="p-0 m-0 font-weight-medium"
                        style={{
                          fontSize: 18,
                          fontWeight: 500,
                          letterSpacing: 0.5,
                        }}
                      >
                        Call Taker
                      </p>
                    </div>
                  </div>
                </div>
                <div className="m-1">
                  <fieldset
                    style={{ border: "1px solid gray" }}
                    className="tab-form-container"
                  >
                    {/* Incident */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Incident</legend>
                      <div className="d-flex">
                        <div
                          className="col-8"
                          style={{ display: "grid", gap: "5px" }}
                        >
                          {/* Row 1 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="tab-form-label" style={{ textAlign: "end", marginRight: "4px" }}>
                                Location {errorCallTaker.location && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Location"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-8 w-100 inner-input-fullw">
                              <Location
                                {...{
                                  value: geoFormValues,
                                  setValue: setGEOFormValues,
                                  locationStatus,
                                  setLocationStatus,
                                  updateStatus,
                                  setOnSelectLocation,
                                  locationData,
                                  setContactList: setContactList,
                                  setGeoLocationID,
                                  setContactInformation,
                                  flagDropDown,
                                  premiseDropDown,
                                  setZoom,
                                  setIsSelectLocation,
                                  setIsChangeFields,
                                  otherZoneDropDown,
                                  emsZoneDropDown,
                                  fireZoneDropDown,
                                  geoZoneDropDown,
                                  setIsGoogleLocation
                                }}
                                col="location"
                                locationID="NameLocationID"
                                check={true}
                                verify={geoFormValues.IsVerify}
                                page="Name"
                                isGEO
                              />
                            </div>
                            <div className="col-3 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label
                                  for=""
                                  class="tab-form-label text-nowrap"
                                >
                                  Apt/Suite#
                                </label>
                              </div>
                              <input
                                type="number"
                                className="form-control  py-1 new-input"
                                name="ApartmentNo"
                                maxLength={4}
                                value={incidentFormValues.ApartmentNo}
                                onChange={handleInputChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 2 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label htmlFor="" className="new-label" style={{ textAlign: "end", marginRight: "5px" }}>
                                CFS :{errorCallTaker.CallforServiceID && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select CFS"}</p>
                                )}
                              </label>
                            </div>
                            <div className="col-2 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CallforServiceID"
                                getOptionLabel={(v) => v?.CFSCODE}
                                getOptionValue={(v) => v?.CallforServiceID}
                                isClearable
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu' ? option.CFSCODE + " | " + option.CFSCodeDescription : option?.CFSCODE
                                }}
                                value={incidentFormValues.CallforServiceID}
                                onChange={handleSelectChangeIncidentCFS}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                            <div className="col-6 d-flex tab-form-row-gap">
                              <Select
                                options={cfsDescDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLDetails"
                                getOptionLabel={(v) => v?.CFSCodeDescription}
                                getOptionValue={(v) => v?.CallforServiceID}
                                isClearable
                                formatOptionLabel={(option, { context }) => {
                                  return context === 'menu' ? option.CFSCODE + " | " + option.CFSCodeDescription : option?.CFSCodeDescription
                                }}
                                value={incidentFormValues.CFSLDetails}
                                onChange={handleSelectChangeIncidentCFS}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>{" "}
                            <div className="col-3 d-flex tab-form-row-gap">
                              <div className=" d-flex align-self-center justify-content-end">
                                <label class="tab-form-label text-nowrap" style={{ textAlign: "end" }}>
                                  Priority {errorCallTaker.CFSLPriority && (
                                    <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Priority"}</p>
                                  )}
                                </label>
                              </div>
                              <Select
                                isClearable
                                options={prioritiesDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="CFSLPriority"
                                value={incidentFormValues.CFSLPriority}
                                onChange={handleSelectChangeIncident}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                          </div>

                          {/* Row 3 */}
                          <div className="tab-form-row d-flex justify-content-center">
                            <div className="col-6 d-flex justify-content-between offset-1">
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSL} disabled onChange={handleCheckboxChangeIncident("CFSL")} />
                                <span className="new-label">L - Law</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSF} onChange={handleCheckboxChangeIncident("CFSF")} />
                                <span className="new-label">F - Fire</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.CFSE} onChange={handleCheckboxChangeIncident("CFSE")} />
                                <span className="new-label">E - Emergency Medical Service</span>
                              </div>
                              <div
                                className="d-flex align-items-center"
                                style={{ gap: "5px" }}
                              >
                                <input type="checkbox" checked={incidentFormValues.OTHER} onChange={handleCheckboxChangeIncident("OTHER")} />
                                <span className="new-label">O - Other</span>
                              </div>
                            </div>
                            <div className="col-4 d-flex tab-form-row-gap offset-1">
                              <label
                                htmlFor=""
                                className="new-label text-nowrap" style={{ textAlign: "end", paddingTop: "8px" }}
                              >
                                Receive Source{errorCallTaker.ReceiveSourceID && (
                                  <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Source"}</p>
                                )}
                              </label>
                              <Select
                                isClearable
                                options={receiveSourceDropDown}
                                placeholder="Select..."
                                styles={colourStylesEC}
                                className="w-100"
                                name="ReceiveSourceID"
                                isSearchable
                                getOptionLabel={(v) => v?.ReceiveSourceCode}
                                getOptionValue={(v) => v?.ReceiveSourceId}
                                value={incidentFormValues.ReceiveSourceID}
                                onChange={handleSelectChangeIncident}
                                onInputChange={(inputValue, actionMeta) => {
                                  if (inputValue.length > 12) {
                                    return inputValue.slice(0, 12);
                                  }
                                  return inputValue;
                                }}
                              />
                            </div>
                          </div>

                          {/* Row 5 */}
                          <div className="tab-form-row d-flex align-items-center">
                            <div className="col-1 d-flex justify-content-end">
                              <label htmlFor="" className="new-label" style={{ textAlign: "end", paddingTop: "2px", marginRight: "4px" }}>
                                Zone
                              </label>
                            </div>
                            <div className="col-11 tab-form-row-gap d-flex w-100">
                              {geoFormValues?.patrolZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"Law " + geoFormValues?.patrolZone?.label}
                                </button></>}
                              {geoFormValues?.fireZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"Fire " + geoFormValues?.fireZone?.label}
                                </button></>}
                              {geoFormValues?.emsZone?.label && <>
                                <button
                                  type="button"
                                  style={{
                                    backgroundColor: "#E0DEDE",
                                    color: "#000",
                                    border: "none",
                                    minWidth: "max-content"
                                  }}
                                  className="btn btn-sm btn-CADprimary1"
                                >
                                  {"EMS " + geoFormValues?.emsZone?.label}
                                </button></>} {geoFormValues?.otherZone?.label && <>
                                  <button
                                    type="button"
                                    style={{
                                      backgroundColor: "#E0DEDE",
                                      color: "#000",
                                      border: "none",
                                      minWidth: "max-content"
                                    }}
                                    className="btn btn-sm btn-CADprimary1"
                                  >
                                    {"Other " + geoFormValues?.otherZone?.label}
                                  </button></>}
                            </div>
                          </div>
                          {/* Row 4 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end">
                              <label for="" class="tab-form-label text-nowrap" style={{ marginRight: "3px" }}>
                                Notes
                              </label>
                            </div>
                            <div className="col-11 d-flex tab-form-row-gap">
                              <input
                                type="text"
                                className="form-control  py-1 new-input"
                                name="Comments"
                                value={incidentFormValues.Comments}
                                onChange={handleInputChangeIncident}
                              />
                            </div>
                          </div>

                          {/* Row 7 */}
                          <div className="tab-form-row">
                            <div className="col-1 d-flex align-self-center justify-content-end" style={{ marginRight: "4px" }}>
                              <label htmlFor="" className="new-label">
                                Flags
                              </label>
                            </div>{" "}
                            <div className="col-9 d-flex tab-form-row-gap">
                              {geoFormValues?.currentFlag?.map(
                                (item, index) => {
                                  const labelStyles = {
                                    Fire: {
                                      backgroundColor: "#FF0000",
                                      color: "#ffff",
                                    },
                                    Tresspass: {
                                      backgroundColor: "#0000FF",
                                      color: "#ffff",
                                    },
                                    "Medical-Emergency": {
                                      backgroundColor: "#008000",
                                      color: "#ffff",
                                    },
                                    Hazardous: {
                                      backgroundColor: "#FFFF00",
                                      color: "#000",
                                    },
                                  };

                                  const defaultStyle = {
                                    backgroundColor: "#e6e6e6",
                                    color: "#ffff",
                                  };

                                  const { backgroundColor, color } =
                                    labelStyles[item?.label] || defaultStyle;

                                  return (
                                    <button
                                      key={index}
                                      type="button"
                                      style={{
                                        backgroundColor,
                                        color,
                                        border: "none",
                                      }}
                                      className="btn btn-sm btn-CADprimary1"
                                    >
                                      {item?.label}
                                    </button>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-4">
                          {isLoaded ? (
                            <GoogleMap
                              mapContainerStyle={{
                                height: "275px",
                              }}
                              zoom={zoom}
                              center={mapCenter}
                              onLoad={(map) => (mapRef.current = map)}
                              onZoomChanged={() => {
                                if (mapRef.current) {
                                  setZoom(mapRef.current.getZoom());
                                }
                              }}
                            >
                              {locations?.map((location, index) => {
                                return (
                                  <Marker
                                    key={index}
                                    position={{
                                      lat: location.lat,
                                      lng: location.lng,
                                    }}
                                    icon={{
                                      path: window.google.maps.SymbolPath
                                        .CIRCLE,
                                      scale: 10,
                                      fillColor: getColorByStatus(
                                        location.status
                                      ),
                                      fillOpacity: 1,
                                      strokeWeight: 1,
                                    }}
                                    title={location.name}
                                    onClick={() =>
                                      console.warn(`Location: ${location.name}`)
                                    }
                                  />
                                );
                              })}
                            </GoogleMap>
                          ) : (
                            <div>Loading...</div>
                          )}
                        </div>
                      </div>
                    </fieldset>

                    {/* Resources */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Resources</legend>
                      <div className="tab-form-row">
                        <div
                          className="col-12 d-flex align-items-center justify-content-start select-container mr-1"
                          style={{ gap: "4.5px" }}
                        >
                          <div>
                            <label className="tab-form-label text-nowrap" style={{ textAlign: "end", marginRight: "4px" }}> Resources # {errorCallTaker.Resource1 && (
                              <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select  Resources"}</p>
                            )}</label>
                          </div>
                          <div className="w-auto" style={{ minWidth: "250px" }}>
                            <Select
                              isClearable
                              options={filterResourcesOptions("Resource1")}
                              placeholder="Select..."
                              name="Resource1"
                              value={incidentFormValues?.Resource1}
                              onChange={handleSelectChangeIncident}
                              styles={colourStylesEC}
                              maxMenuHeight={180}
                              onInputChange={(inputValue, actionMeta) => {
                                if (inputValue.length > 12) {
                                  return inputValue.slice(0, 12);
                                }
                                return inputValue;
                              }}
                              isMulti
                              isSearchable={true}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>

                    {/* Name */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Name</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Last Name
                            </label>
                          </div>
                          <input
                            type="text"
                            value={nameCallTaker.LastName}
                            name="LastName"
                            onChange={(e) => {
                              handleNameCallTaker("LastName", e.target.value); setIsChangeFields(true);
                            }}

                            className="form-control  py-1 new-input  ml-2"
                            onKeyDown={handleTextKeyDown}
                          />{" "}
                          <div className="d-flex align-self-center justify-content-end">
                            <label htmlFor="" className="new-label text-nowrap tab-form-label">
                              First Name
                            </label>
                          </div>
                          <input
                            type="text"
                            className="form-control py-1 new-input"
                            name="FirstName"
                            value={nameCallTaker.FirstName}
                            onChange={(e) => { handleNameCallTaker("FirstName", e.target.value); setIsChangeFields(true); }}
                            onKeyDown={handleTextKeyDown}
                          />

                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Reason Code
                            </label>
                          </div>
                          {/* styles={customStylesWithOutColorArrow} */}
                          <Select
                            isClearable
                            options={reasonCodeDropDown}
                            placeholder="Select..."
                            className="w-100"
                            name="NameReasonCodeID"
                            isMulti
                            closeMenuOnSelect={false}
                            hideSelectedOptions={true}
                            allowSelectAll={true}
                            value={reasonCodeDropDown.filter(option => nameCallTaker.NameReasonCodeID.includes(option.value))}
                            onChange={handleSelectIncidentName}
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                height: '40px',
                                minHeight: '40px',
                                overflowY: 'hidden'
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                height: 'auto',
                                maxHeight: '40px',
                                overflowY: 'auto',
                                padding: '0 8px',
                              }),
                              dropdownIndicator: (base, state) => ({
                                ...base,
                                transition: "all .2s ease",
                                transform: state.selectProps.menuIsOpen ? null : "rotate(180deg)"
                              }),
                              input: (provided) => ({
                                ...provided,
                                minWidth: '0px',
                                maxWidth: '100%',
                              }),
                            }}
                          />


                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addName}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => {
                              onNameSearch();
                            }}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                    {/* Vehicle */}
                    <fieldset className="tab-form-container">
                      <legend className="cad-legend">Vehicle</legend>
                      <div className="tab-form-row">
                        <div className="col-10 d-flex tab-form-row-gap">
                          <div className="col d-flex align-self-center justify-content-end ml-4">
                            <label htmlFor="" className="tab-form-label new-label text-nowrap">
                              Plate
                            </label>
                          </div>
                          <input
                            type="text"
                            name="Plate"
                            value={vehicleCallTaker.Plate}
                            onChange={(e) => handleVehicleCallTaker("Plate", e.target.value)}
                            className="form-control  py-1 new-input ml-2"
                            onKeyDown={handleNumberTextKeyDown}
                            menuPlacement="top"
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              State
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={stateDropDown}
                            placeholder="Select..."
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            name="StateCode"
                            value={vehicleCallTaker.StateCode}
                            onChange={handleSelectIncidentVehicle}
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Plate Type
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={vehiclePlateTypeDropDown}
                            placeholder="Select..."
                            name="PlateTypeCode"
                            value={vehicleCallTaker.PlateTypeCode}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                          <div className=" d-flex align-self-center justify-content-end">
                            <label
                              htmlFor=""
                              className="tab-form-label text-nowrap"
                            >
                              Tag Year
                            </label>
                          </div>
                          <Select
                            isClearable
                            options={tagYearDropDown?.find((i) => i?.value === vehicleCallTaker.TagYear?.value)}
                            placeholder="Select..."
                            name="TagYear"
                            value={vehicleCallTaker.TagYear}
                            onChange={handleSelectIncidentVehicle}
                            styles={customStylesWithOutColorArrow}
                            className="w-100"
                            menuPlacement="top"
                            onInputChange={(inputValue, actionMeta) => {
                              if (inputValue.length > 12) {
                                return inputValue.slice(0, 12);
                              }
                              return inputValue;
                            }}
                          />
                        </div>
                        <div
                          className="col-2 d-flex w-100"
                          style={{ gap: "3px" }}
                        >
                          <button
                            type="button"
                            className="btn btn-sm btn-border w-25"
                            onClick={addVehicle}
                          >
                            <i className="fa fa-plus"></i>
                          </button>
                          <button type="button" data-toggle="modal" data-target="#SearchModal" className="btn btn-sm btn-CADprimary w-100 d-flex align-items-center justify-content-center"
                            onClick={() => setIsOpenVehicleSearchModel(true)}
                          >
                            <i className="fa fa-search mr-2"></i>
                            <span>Search</span>
                          </button>
                        </div>
                      </div>
                    </fieldset>

                  </fieldset>
                </div>
              </div>

              {/* Shortcut key and Buttons */}
              <div
                className="tab-form-row d-flex align-content-center px-3 py-2"
                style={{
                  position: "sticky",
                  bottom: "0",
                  zIndex: 100,
                  backgroundColor: "white",
                  marginBottom: "0",
                }}
              >
                <div className="col-9 d-flex tab-form-row-gap justify-content-center">
                  <button type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(2) ? 'btn-border selected' : 'btn-border'}`}
                    onClick={() => {
                      handleOnSave();
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Dispatch</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(3) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#LocationInformationModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(3)
                          ? prevSelected.filter(item => item !== 3)
                          : [...prevSelected, 3]
                      );
                      setOpenLocationInformationModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(4) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#GEOModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(4)
                          ? prevSelected.filter(item => item !== 4)
                          : [...prevSelected, 4]
                      );
                      setOpenGEOModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>GEO</span>
                    </div>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(5) ? 'btn-border selected' : 'btn-border'}`}
                    data-toggle="modal"
                    data-target="#ContactInfoModal"
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(5)
                          ? prevSelected.filter(item => item !== 5)
                          : [...prevSelected, 5]
                      );
                      setOpenContactInfoModal(true);
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Contact</span>
                    </div>
                  </button>
                  <button type="button"
                    className={`btn btn-sm w-25 ${selectedButton.includes(6) ? 'btn-border selected' : 'btn-border'}`}
                    onClick={() => {
                      setSelectedButton(prevSelected =>
                        prevSelected.includes(6)
                          ? prevSelected.filter(item => item !== 6)
                          : [...prevSelected, 6]
                      );
                    }}
                  >
                    <div style={{ display: "grid" }}>
                      <span>Location Flag</span>
                    </div>
                  </button>
                </div>
                <div className="col-3 d-flex justify-content-end tab-form-row-gap">
                  <button
                    type="button"
                    className="cancel-button"
                    onClick={() => {
                      if (isChangeFields) {
                        setShowConfirmModal(true);
                      } else {
                        onCloseLocation();
                      }
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </dialog>
      ) : (
        <> </>
      )}
      <GEOModal {...{ openGEOModal, setOpenGEOModal, setSelectedButton }} />
      <ModalConfirm showModal={showConfirmModal} setShowModal={setShowConfirmModal} confirmAction="close" handleConfirm={handleCloseConfirm} />
      {isOpenSearchNameModel && <NameSearchModal {...{ isOpenSearchNameModel, setIsOpenSearchNameModel, nameCallTaker, setNameCallTaker }} />}
      {isOpenVehicleSearchModel && <VehicleSearchModal {...{ isOpenVehicleSearchModel, setIsOpenVehicleSearchModel, vehicleCallTaker, setVehicleCallTaker }} />}
      {openContactInfoModal && <ContactInfoModal {...{ openContactInfoModal, setOpenContactInfoModal, setSelectedButton, contactList, setContactList, isGoogleLocation }} />}
      <LocationInformationModal {...{ openLocationInformationModal, setOpenLocationInformationModal, setSelectedButton, geoFormValues, setGEOFormValues, isGoogleLocation }} />
    </>
  );
};

export default memo(CallTakerModal);
