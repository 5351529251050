import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { tableCustomStyles } from "../../Components/Common/Utility";
import { phoneTypes } from "../../CADUtils/constant";

function GEOContactTable({ contactList, setContactInformation, setContactList, setItemId, setEditItemId, setIsEditMode, contactInformation, isGoogleLocation = false }) {
  const [editValue, setEditValue] = useState("");

  const set_Edit_Value = (row) => {
    const PhoneType = phoneTypes.find(phoneType => phoneType.label === row.PhoneType);
    const updatedRow = {
      ...row,
      PhoneType,
    };
    setContactInformation(updatedRow);
    setIsEditMode(true);
    setEditItemId(row.ID);
  };

  const handleDeleteContact = (id) => {
    if (id === contactInformation?.ID) {
      setIsEditMode(false)
    }
    setContactList((prevContactList) => prevContactList.filter(contact => contact.ID !== id));
  };

  const columns = [
    {
      name: (
        <p className="text-end" style={{ position: "absolute", top: "7px" }}>
          Last Name
        </p>
      ),
      cell: (row) => <div>{row.LastName}</div>,
      selector: (row) => (row.LastName ? row.LastName : ""),
    },
    {
      name: "Middle Name",
      selector: (row) => (row.MiddleName ? row.MiddleName : ""),

      sortable: true,
    },
    {
      name: "First Name",
      selector: (row) => (row.FirstName ? row.FirstName : ""),

      sortable: true,
    },
    {
      name: "Phone Type",
      selector: (row) => (row.PhoneType ? row.PhoneType : ""),
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) =>
        row.PhoneNo || "",
      sortable: true,
    },
    {
      width: "68px",
      name: (
        <div
          className="d-flex justify-content-end"
        // style={{ position: "absolute", top: "7px", right: 0 }}
        >
          Delete
        </div>
      ),
      cell: (row) => (
        <div className="d-flex justify-content-end btn btn-sm bg-green text-white px-1 py-0 mr-1" onClick={isGoogleLocation ? () =>
          handleDeleteContact(row.ID) : () => { }
        }>
          <i className="fa fa-trash"></i>
        </div>
      ),
    },
  ];
  const conditionalRowStyles = [
    {
      when: row => row === editValue,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    }
  ];
  return (
    <div className="table-responsive">
      <DataTable
        dense
        columns={columns}
        data={contactList}
        customStyles={tableCustomStyles}
        conditionalRowStyles={conditionalRowStyles}
        pagination
        responsive
        striped
        highlightOnHover
        fixedHeader
        selectableRowsHighlight
        fixedHeaderScrollHeight="250px"
        persistTableHead={true}
        onRowClicked={isGoogleLocation ? (row) => {
          set_Edit_Value(row);
          setEditValue(row);
        } : () => { }}
      />
    </div>
  );
}

export default GEOContactTable;
