import axios from "axios";
class MonitorServices {
  getIncidentData = async (payload) => {
    // if (payload) {
    return await axios.post(
      `/CAD/CallTakerIncident/GetData_Incident`);
  }
  // console.warn("payload not provided, MonitorServices.getIncidentData");
  // return null;
  // };

  getSingleIncident = async ({ queryKey }) => {
    const [_key, payload] = queryKey;
    // if (payload) {
    return await axios.post(`/CAD/Monitor/MonitorIncidentByID`, payload);
    // }
    // console.warn("payload not provided, MonitorServices.getSingleIncident");
    // return null;
  };

  GetComments = async ({ queryKey }) => {
    const [_key, payload] = queryKey;
    // if (payload) {
    return await axios.post(`/CAD/DispatcherComments/GetData_Comments`, payload);
    // }
    // console.warn("payload not provided, MonitorServices.getSingleIncident");
    // return null;
  };

  getResourceView = async ({ queryKey }) => {
    const [_key, payload] = queryKey;
    if (payload) {
      return await axios.post(`/CAD/Monitor/MonitorResourceView`, payload);
    }
    console.warn("payload not provided, MonitorServices.getResourceView");
    return null;
  };

  getIncidentsView = async ({ queryKey }) => {
    const [_key, payload] = queryKey;
    if (payload) {
      return await axios.post(`/CAD/Monitor/MonitorIncidentsView`, payload);
    }
    console.warn("payload not provided, MonitorServices.getIncidentsView");
    return null;
  };

}

const instance = new MonitorServices();

export default instance;
