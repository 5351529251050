import React, { useContext, useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './index.css';
import { IncidentContext } from '../../CADContext/Incident';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon-2x.png',
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
});

const FitBoundsView = ({ bounds }) => {
  const map = useMap();
  useEffect(() => {
    if (bounds) {
      map.fitBounds(bounds); // Fit the map to the bounds of the markers
    }
  }, [map, bounds]);
  return null;
};

const FullscreenControl = () => {
  const map = useMap();
  useEffect(() => {
    const fullscreenControl = L.control({ position: 'topleft' });
    fullscreenControl.onAdd = function () {
      const div = L.DomUtil.create('div', 'leaflet-bar fullscreen-control');
      div.innerHTML = '&#x26F6;';
      div.style.cursor = 'pointer';
      div.style.fontSize = '20px';
      div.style.width = '30px';
      div.style.height = '30px';
      div.style.display = 'flex';
      div.style.justifyContent = 'center';
      div.style.alignItems = 'center';
      div.title = 'Toggle fullscreen';

      div.onclick = function () {
        const mapContainer = map.getContainer();
        if (!document.fullscreenElement) {
          mapContainer.requestFullscreen(); // Enter fullscreen
        } else if (document.exitFullscreen) {
          document.exitFullscreen(); // Exit fullscreen
        }
      };

      return div;
    };

    fullscreenControl.addTo(map);

    return () => {
      map.removeControl(fullscreenControl);
    };
  }, [map]);

  return null;
};

const CADMap = () => {
  const [bounds, setBounds] = useState(null);
  const { incidentData, isFetchIncidentList } = useContext(IncidentContext);
  useEffect(() => {
    if (incidentData?.length > 0) {
      const validLocations = incidentData
        .filter(loc => loc.Latitude && loc.Longitude) // Filter out invalid coordinates
        .map(loc => [loc.Latitude, loc.Longitude]);

      if (validLocations.length > 0) {
        setBounds(L.latLngBounds(validLocations));
      }
    }
  }, [incidentData]);

  return (
    <MapContainer className="full-screen-map" bounds={bounds}>
      {bounds && <FitBoundsView bounds={bounds} />}
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> contributors"
      />
      <FullscreenControl /> {/* Fullscreen control */}
      {incidentData?.map((location, index) => (
        location.Latitude && location.Longitude && ( // Only render valid locations
          <Marker
            key={index}
            position={[location.Latitude, location.Longitude]}
            icon={
              new L.DivIcon({
                className: 'custom-marker', // Custom CSS class
                html: `<div class="marker-container">
        <img src='${location.Icon || "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png"}' style="width: 25px; height: 41px;" />
        <div>
          <div class="marker-title">${location.CrimeLocation.length > 20 ? location.CrimeLocation.slice(0, 20) + '...' : location.CrimeLocation}</div>
          ${location.CADIncidentNumber ? `<div class="marker-info">${location.CADIncidentNumber}</div>` : ''}
          ${location.CFSCodeDescription ? `<div class="marker-info">${location.CFSCodeDescription}</div>` : ''}
        </div>
      </div>`,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          >
            <Popup>
              <div>
                <strong>Location:</strong> {location.CrimeLocation} <br />
                {location.CADIncidentNumber && (
                  <>
                    <strong>Incident No.:</strong> {location.CADIncidentNumber} <br />
                  </>
                )}
                {location.CFSCodeDescription && (
                  <>
                    <strong>CFS Description:</strong> {location.CFSCodeDescription} <br />
                  </>
                )}
              </div>
            </Popup>
            <Tooltip>{location.CrimeLocation}</Tooltip>
          </Marker>
        )
      ))}
    </MapContainer>
  );
};

export default CADMap;
