import React, { memo, useEffect, useState } from "react";
import Select from "react-select";
import useObjState from "../../CADHook/useObjState";
import MonitorServices from '../../CADServices/APIs/monitor'
import ResourcesStatusServices from "../../CADServices/APIs/resourcesStatus";
import { useQuery } from "react-query";
import { toastifySuccess } from "../../Components/Common/AlertMsg";
import { useSelector } from "react-redux";

const EnrouteModal = (props) => {
  const { openEnrouteModal, setEnrouteModal } = props;
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [resourceDropDown, setResourceDropDown] = useState([])
  const [hospitalCodeDropDown, setHospitalCodeDropDown] = useState([])
  const [loginAgencyID, setLoginAgencyID] = useState();
  const [incidentsDropDown, setIncidentsDropDown] = useState([])

  const [
    enrouteState,
    _setEnrouteState,
    handleEnrouteState,
    clearEnrouteState,
  ] = useObjState({
    EnroutesceneorHospital: "scene",
    IncidentID: "",
    Resources1: "",
    Resources2: "",
    Resources3: "",
    Resources4: "",
    Resources5: "",
    Resources6: "",
    Resources7: "",
    Resources8: "",
    HospitalNameCodeID: "",
    Comments: ""
  });

  const getIncidentsKey = `/CAD/CallTakerIncident/GetData_Incident_dp`;
  const { data: getIncidentsData, isSuccess: isFetchIncidentData } = useQuery(
    [getIncidentsKey, {},],
    ResourcesStatusServices.getIncidents,
    {
      refetchOnWindowFocus: false,
      enabled: openEnrouteModal
    }
  );

  const getHospitalNameCodeKey = `/CAD/MasterHospitalNamecode/InsertMasterHospitalNamecode`;
  const { data: getHospitalNameCodeData, isSuccess: isFetchGetHospitalNameCodeKey } = useQuery(
    [getHospitalNameCodeKey, { Action: "GET", "isactive": 1 },],
    ResourcesStatusServices.getHospitalNameCode,
    {
      refetchOnWindowFocus: false,
      enabled: openEnrouteModal
    }
  );

  const getResourcesKey = `/CAD/CallTackerResource/GetData_Resources`;
  const { data: getResourcesData, isSuccess } = useQuery(
    [getResourcesKey, { AgencyID: loginAgencyID },],
    MonitorServices.getResourceView,
    {
      refetchOnWindowFocus: false,
      enabled: openEnrouteModal
    }
  );

  useEffect(() => {
    if (localStoreData) {
      setLoginAgencyID(localStoreData?.AgencyID)
    }
  }, [localStoreData]);

  useEffect(() => {
    if (getResourcesData && isSuccess) {
      const resources = JSON.parse(getResourcesData?.data?.data)?.Table || []
      setResourceDropDown(resources)
    }
  }, [getResourcesData, isSuccess])

  useEffect(() => {
    if (isFetchIncidentData && getIncidentsData) {
      const data = JSON.parse(getIncidentsData?.data?.data);
      setIncidentsDropDown(data?.Table)
    }
  }, [isFetchIncidentData, getIncidentsData]);

  useEffect(() => {
    if (getHospitalNameCodeData && isFetchGetHospitalNameCodeKey) {
      const data = JSON.parse(getHospitalNameCodeData?.data?.data);
      setHospitalCodeDropDown(data?.Table)
    }
  }, [getHospitalNameCodeData, isFetchGetHospitalNameCodeKey])

  const handleClose = () => {
    clearEnrouteState();
    setEnrouteModal(false);
  };

  async function handleSave() {
    const data = {
      ResourceStatus: "Enroute",
      EnroutesceneorHospital: enrouteState?.EnroutesceneorHospital === "scene" ? 1 : 0,
      IncidentID: enrouteState?.IncidentID?.IncidentID,
      Resources1: enrouteState?.Resources1?.ResourceID,
      Resources2: enrouteState?.Resources2?.ResourceID,
      Resources3: enrouteState?.Resources3?.ResourceID,
      Resources4: enrouteState?.Resources4?.ResourceID,
      Resources5: enrouteState?.Resources5?.ResourceID,
      Resources6: enrouteState?.Resources6?.ResourceID,
      Resources7: enrouteState?.Resources7?.ResourceID,
      Resources8: enrouteState?.Resources8?.ResourceID,
      HospitalNameCodeID: enrouteState?.HospitalNameCodeID?.HospitalNameCodeID,
      Comments: enrouteState?.Comments
    }
    const response = await ResourcesStatusServices.insertDispatcher(data);
    if (response?.status === 200) {
      toastifySuccess("Data Saved Successfully");
      handleClose();
    }
  }

  const customStylesWithOutColor = {
    control: (base) => ({
      ...base,
      height: 20,
      minHeight: 35,
      fontSize: 14,
      marginTop: 2,
      boxShadow: 0,
    }),
  };

  const getFilteredOptions = (selectedKey) => {
    const selectedOfficers = [
      enrouteState?.Resources1,
      enrouteState?.Resources2,
      enrouteState?.Resources3,
      enrouteState?.Resources4,
      enrouteState?.Resources5,
      enrouteState?.Resources6,
      enrouteState?.Resources7,
      enrouteState?.Resources8,
    ].filter(Boolean);

    return resourceDropDown.filter(
      (officer) => !selectedOfficers.some((selected) => selected?.ResourceID === officer?.ResourceID) || selectedKey?.ResourceID === officer?.ResourceID
    );
  };

  return (
    <>
      {openEnrouteModal ? (
        <>
          <dialog
            className="modal fade"
            style={{ background: "rgba(0,0,0, 0.5)", zIndex: "200" }}
            id="EnrouteModal"
            tabIndex="-1"
            aria-hidden="true"
            data-backdrop="false"
          >
            <div className="modal-dialog modal-dialog-centered modal-xl">
              <div className="modal-content modal-content-cad">
                <div className="modal-body">
                  <div className="row">
                    <div className="col-12 p-0 pb-2">
                      <div className="py-0 px-2 d-flex justify-content-between align-items-center">
                        <p
                          className="p-0 m-0 font-weight-medium"
                          style={{
                            fontSize: 18,
                            fontWeight: 500,
                            letterSpacing: 0.5,
                          }}
                        >
                          Enroute
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* Form Section */}
                  <div className="m-1">
                    <fieldset style={{ border: "1px solid gray" }}>
                      <div className="tab-form-container">
                        {/* Line 1 */}
                        <div className="tab-form-row">
                          <div className="col-1 d-flex align-self-center justify-content-end">
                            <label className="tab-form-label">
                              Enroute To
                            </label>
                          </div>
                          <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                            <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                              <input type="radio" id="scene" value="scene" checked={enrouteState?.EnroutesceneorHospital === 'scene'} onChange={(e) => { handleEnrouteState("EnroutesceneorHospital", e.target.value) }} />
                              <label for="scene" style={{ margin: '0', }}>Scene</label>
                            </div>
                          </div>
                          <div className="d-flex align-self-center justify-content-start" style={{ width: '120px' }}>
                            <div className='d-flex align-self-center justify-content-start' style={{ gap: '5px' }}>
                              <input type="radio" id="hospital" value="hospital" checked={enrouteState?.EnroutesceneorHospital === 'hospital'} onChange={(e) => { handleEnrouteState("EnroutesceneorHospital", e.target.value) }} />
                              <label for="hospital" style={{ margin: '0', }}>Hospital</label>
                            </div>
                          </div>
                        </div>

                        {/* Line 2 */}
                        <div className="tab-form-row">
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            <label className="tab-form-label">
                              Incident #
                            </label>
                          </div>
                          <div className="col-2 d-flex align-items-center justify-content-end">
                            <Select
                              name="Incident"
                              styles={customStylesWithOutColor}
                              isClearable
                              options={incidentsDropDown}
                              value={enrouteState?.IncidentID}
                              getOptionLabel={(v) => v?.IncidentNumber}
                              getOptionValue={(v) => v?.IncidentID}
                              onChange={(e) => handleEnrouteState("IncidentID", e)}
                              placeholder="Select..."
                              className="w-100"
                            />
                          </div>
                        </div>

                        {/* Line 3 */}
                        <div className="tab-form-row">
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            <label className="tab-form-label">
                              Resource #
                            </label>
                          </div>
                          <div className="col-11 d-flex align-items-center justify-content-end" style={{ gap: '10px' }}>
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources1")}
                              placeholder="Select..."
                              className="w-100"
                              name="Resource1"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources1}
                              onChange={(e) => handleEnrouteState("Resources1", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources2")}
                              name="Resource2"
                              placeholder="Select..."
                              className="w-100"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources2}
                              onChange={(e) => handleEnrouteState("Resources2", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              placeholder="Select..."
                              name="Resource3"
                              className="w-100"
                              options={getFilteredOptions("enrouteState?.Resources3")}
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources3}
                              onChange={(e) => handleEnrouteState("Resources3", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources4")}
                              placeholder="Select..."
                              className="w-100"
                              name="Resource4"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources4}
                              onChange={(e) => handleEnrouteState("Resources4", e)}
                              styles={customStylesWithOutColor}
                            />
                          </div>
                        </div>

                        {/* Line 4 */}
                        <div className="tab-form-row">
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            {/* Empty Space */}
                          </div>
                          <div className="col-11 d-flex align-items-center justify-content-end" style={{ gap: '10px' }}>
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources5")}
                              placeholder="Select..."
                              className="w-100"
                              name="Resource1"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources5}
                              onChange={(e) => handleEnrouteState("Resources5", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources6")}
                              name="Resource2"
                              placeholder="Select..."
                              className="w-100"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources6}
                              onChange={(e) => handleEnrouteState("Resources6", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources7")}
                              placeholder="Select..."
                              name="Resource3"
                              className="w-100"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources7}
                              onChange={(e) => handleEnrouteState("Resources7", e)}
                              styles={customStylesWithOutColor}
                            />
                            <Select
                              isClearable
                              options={getFilteredOptions("enrouteState?.Resources8")}
                              placeholder="Select..."
                              className="w-100"
                              name="Resource4"
                              getOptionLabel={(v) => v?.ResourceNumber}
                              getOptionValue={(v) => v?.ResourceID}
                              value={enrouteState?.Resources8}
                              onChange={(e) => handleEnrouteState("Resources8", e)}
                              styles={customStylesWithOutColor}
                            />
                          </div>
                        </div>

                        {/* Line 5 */}
                        <div className="tab-form-row">
                          <div className="col-1 d-flex align-items-center justify-content-end">
                            <label className="tab-form-label">
                              Hospital Code
                            </label>
                          </div>
                          <div className="col-2 d-flex align-items-center justify-content-end">
                            <Select
                              isClearable
                              options={hospitalCodeDropDown}
                              placeholder="Select..."
                              className="w-100"
                              name="Resource4"
                              getOptionLabel={(v) => v?.hospitalnamecode}
                              getOptionValue={(v) => v?.HospitalNameCodeID}
                              value={enrouteState?.HospitalNameCodeID}
                              onChange={(e) => handleEnrouteState("HospitalNameCodeID", e)}
                              styles={customStylesWithOutColor}
                            />
                          </div>
                        </div>

                        {/* Line 6 */}
                        <div className="tab-form-row" style={{ alignItems: 'baseline' }}>
                          <div className="col-1 d-flex align-items-end justify-content-end">
                            <label className="tab-form-label">
                              Comments
                            </label>
                          </div>
                          <div className="col-11 d-flex align-items-center justify-content-end">
                            <textarea
                              type="text"
                              className="form-control  py-1 new-input"
                              placeholder="Placeholder"
                              value={enrouteState?.Comments}
                              onChange={(e) => handleEnrouteState("Comments", e.target.value)}
                              style={{ height: '60px' }}
                            />
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                  {/* Buttons Section */}
                  <div className="row">
                    <div className="col-12 p-0">
                      <div className="py-0 px-2 d-flex justify-content-end align-items-center">
                        <div className="d-flex justify-content-end tab-form-row-gap mt-1">
                          <button
                            type="button"
                            className="save-button ml-2"
                            onClick={() => handleSave()}
                          >
                            {'Save'}
                          </button>
                          <button
                            type="button"
                            data-dismiss="modal"
                            className="cancel-button"
                            onClick={() => handleClose()}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </dialog >
        </>
      ) : (
        <> </>
      )
      }
    </>
  );
};

export default memo(EnrouteModal);
