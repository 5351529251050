import axios from "axios";
class MasterTableListServices {

    insertCFS = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertCFS");
        return null;
    };

    getCFS = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/InsertCallforServiceCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getCFS");
        return null;
    };

    getShift = async ({ queryKey }) => {
        const [_key, payloadShift] = queryKey;
        if (payloadShift) {
            return await axios.post(`/CAD/MasterResourceShift/GetData_Shift`, payloadShift);
        }
        console.warn("payload not provided, MasterTableListServices.getShift");
        return null;
    };

    getStationCode = async ({ queryKey }) => {
        const [_key, payloadStationCode] = queryKey;
        if (payloadStationCode) {
            return await axios.post(`/CAD/MasterStationCode/StationCode`, payloadStationCode);
        }
        console.warn("payload not provided, MasterTableListServices.GetStationCode");
        return null;
    };

    getResourceType = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/GetResourceType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.GetResourceType");
        return null;
    };

    getHospitalStatusCode = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalStatusCode/HospitalStatusCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getHospitalStatusCode");
        return null;
    };

    insertHospitalStatusCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterHospitalStatusCode/HospitalStatusCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertHospitalStatusCode");
        return null;
    };


    getDataZone = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/GetData_Zone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.GetDataZone");
        return null;
    };

    getResources = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterResource/GetResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getResources");
        return null;
    };

    getByIncidentResources = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/CallTackerResource/GetData_Resources_ByIncidentId`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getByIncidentResources");
        return null;
    };

    getPrimaryOfficer = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/CAD/MasterPrimaryOfficer/PrimaryOfficer`);
    };

    getAgencyCode = async ({ queryKey }) => {
        const [_key] = queryKey;
        return await axios.post(`/CAD/MasterAgency/MasterAgencyCode`);
    };

    insertResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResource/upsertResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.InsertResource");
        return null;
    };

    updateResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/CallTackerResource/UpdateResource`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateResource");
        return null;
    };

    insertResourceType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/InsertResourceType`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertResourceType");
        return null;
    };

    // insertShift = async (payload) => {
    //     if (payload) {
    //         return await axios.post(`/CAD/MasterShift/InsertShift`, payload);
    //     }
    //     console.warn("payload not provided, MasterTableListServices.insertShift");
    //     return null;
    // };

    insertZone = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/InsertZone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertZone");
        return null;
    };

    updateZone = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/UpdateZone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.updateZone");
        return null;
    };

    changeStatusStationCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterStationCode/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusStationCode");
        return null;
    };

    changeStatusMasterResource = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResource/ChangeStatus`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusMasterResource");
        return null;
    };

    // changeStatusMasterShift = async (payload) => {
    //     if (payload) {
    //         return await axios.post(`/CAD/MasterShift/change_status`, payload);
    //     }
    //     console.warn("payload not provided, MasterTableListServices.changeStatusMasterShift");
    //     return null;
    // };

    changeStatusAgencyCallFilter = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusAgencyCallFilter");
        return null;
    };

    changeStatusResourceType = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterResourceType/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusResourceType");
        return null;
    };

    changeStatusCallforServiceCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCallforServiceCode/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusCallforServiceCode");
        return null;
    };
    changeStatusZone = async (payload) => {
        if (payload) {
            return await axios.post(`CAD/GeoPetrolZone/change_status`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.changeStatusZone");
        return null;
    };

    getZone = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/GeoPetrolZone/GetData_Zone`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getZone");
        return null;
    };

    insertUpdateStationCode = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterStationCode/StationCode`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.InsertResource");
        return null;
    };

    getCFSAgencyCallFilter = async ({ queryKey }) => {
        const [_key, payload] = queryKey;
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/GET_CFSagencycallfilter`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.getCFSAgencyCallFilter");
        return null;
    };

    insertCFSAgencyCallFilter = async (payload) => {
        if (payload) {
            return await axios.post(`/CAD/MasterCFSagencycallfilter/Insert_CFSagencycallfilter`, payload);
        }
        console.warn("payload not provided, MasterTableListServices.insertCFSAgencyCallFilter");
        return null;
    };
}

const instance = new MasterTableListServices();

export default instance;
