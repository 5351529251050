import React, { useState } from "react";
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd } from '@fortawesome/free-solid-svg-icons';
import { base64ToString, getShowingYearMonthDate, tableCustomStyles } from '../../../../Components/Common/Utility';
import MonitorServices from '../../../../CADServices/APIs/monitor'
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import useObjState from "../../../../CADHook/useObjState";

const columns = [
  {
    name: 'Comment Date & Time',
    selector: row => row.commentDateTime,
    sortable: true,
    format: row => row?.CommentDateTime ? getShowingYearMonthDate(row?.CommentDateTime) : "",
  },
  {
    name: 'Operator Name',
    selector: row => row.OperatorName,
    sortable: true,
  },
  {
    name: 'Comments',
    selector: row => row.Comments,
    sortable: false,
    width: "60%"
  },
  {
    name: 'Resource #',
    selector: row => row.resourceNumber,
    sortable: true,
  },
];

const CommentsTabFrom = () => {
  const useURLQuery = () => {
    const params = new URLSearchParams(useLocation().search);
    return {
      get: (param) => params.get(param)
    };
  };

  const query = useURLQuery();
  var IncID = query?.get("IncId");
  if (!IncID) IncID = 0;
  else IncID = parseInt(base64ToString(IncID));

  const [
    commentState,
    setCommentState,
    handleCommentState,
    clearCommentState,
  ] = useObjState({
    incidentId: "",
    location: "",
    comments: ""
  })


  const [data, setData] = useState([])
  const getSingleIncidentKey = `/CAD/DispatcherComments/GetData_Comments`;
  const { data: singleIncidentData, isSuccess: isFetchSingleIncidentData } = useQuery(
    [getSingleIncidentKey, { IncidentID: IncID }],
    MonitorServices.GetComments,
    {
      refetchOnWindowFocus: false,
      enabled: !!IncID,
      onSuccess: (data) => {
        const parsedData = JSON.parse(data?.data?.data)?.Table || [];
        setData(parsedData);
      },
    }
  );

  return (
    <>
      <div className="tab-form-monitor-container">
        <div className="tab-form-row">
          <div className="col-2 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Incident #
            </label>
          </div>
          <div className="col-2 d-flex align-self-center justify-content-end">
            <input
              type="text"
              className="form-control py-1 new-input highlighter-input-readonly"
              value={commentState?.incidentId}
              readonly=""
            />
          </div>
          <div className="col-1 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Location
            </label>
          </div>
          <div className="col-6 d-flex align-self-center justify-content-end">
            <input
              type="text"
              value={commentState?.location}
              className="form-control py-1 new-input"
            />
          </div>
        </div>
        <div className="tab-form-row">
          <div className="col-2 d-flex align-self-center justify-content-end">
            <label for="" className="tab-form-label">
              Comments
            </label>
          </div>
          <div className="col-9 d-flex align-self-center justify-content-end">
            <textarea
              className="form-control py-1 new-input"
              value={commentState?.comments}
              onChange={(e) => handleCommentState("comments", e.target.value)}
              rows="3"
            />
          </div>
        </div>
        <div className="tab-form-row from-button-container" >
          <button className="save-button d-flex align-items-center" style={{ gap: '5px' }}>
            <FontAwesomeIcon icon={faAdd} style={{ color: 'white' }} />
            {"Add Comment"}
          </button>
        </div>
      </div>
      <div className="tab-table-container">
        <DataTable
          dense
          columns={columns}
          data={data}
          customStyles={tableCustomStyles}
          pagination // enable pagination
          responsive // enable responsive design
          striped // add zebra-striping to the table rows
          highlightOnHover // highlight row on hover
          fixedHeader
        // fixedHeaderScrollHeight="200px" 
        />
      </div>
    </>
  );
};

export default CommentsTabFrom;
