import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import Select from "react-select";
import DataTable from 'react-data-table-component';
import { base64ToString, getShowingDateText, getShowingMonthDateYear, getShowingWithOutTime, stringToBase64, tableCustomStyles } from '../../Common/Utility';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useContext } from 'react'
import { Comman_changeArrayFormat } from '../../Common/ChangeArrayFormat';
import { fetchPostData } from '../../hooks/Api';

import { useLocation, useNavigate } from 'react-router-dom';
import ConfirmModal from '../Arrest/ConfirmModal';
import ExpungeConfirmModel from '../../Common/ExpungeConfirmModel';
import { toastifyError, toastifySuccess } from '../../Common/AlertMsg';

const Expunge = () => {

    const navigate = useNavigate();

    const localStoreData = useSelector((state) => state.Agency.localStoreData);

    const [loginAgencyID, setloginAgencyID] = useState('');
    const [ethinicityDrpData, setEthinicityDrpData] = useState([]);
    const [sexIdDrp, setSexIdDrp] = useState([]);
    const [raceIdDrp, setRaceIdDrp] = useState([]);
    const [dateOfbirth, setdateOfbirth] = useState();
    const [nameSearchValue, setNameSearchValue] = useState([]);
    const [logData, setLogData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loginPinID, setLoginPinID,] = useState('');
    const [nameId, setNameID] = useState('');
    const [masterNameID, setMasterNameID] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [initialExpungeDone, setInitialExpungeDone] = useState(false);
    const [clearSelectedRows, setClearSelectedRows] = useState(false);
    const [notes, setNotes] = useState('');
    const [statesChangeStatus, setStatesChangeStatus] = useState(false);



    const [value, setValue] = useState({
        'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '',

    })


    const useQuery = () => {
        const params = new URLSearchParams(useLocation().search);
        return {
            get: (param) => params.get(param)
        };
    };

    let DeNameID = 0, DeMasterNameID = 0

    const query = useQuery();
    var IncID = query?.get("IncId");
    var IncNo = query?.get("IncNo");
    var IncSta = query?.get("IncSta");
    let openPage = query?.get('page');
    var NameID = query?.get("NameID");
    var NameStatus = query?.get("NameStatus");
    var MasterNameID = query?.get("MasterNameID");
    let MstPage = query?.get('page');

    if (!IncID) IncID = 0;
    else IncID = parseInt(base64ToString(IncID));
    if (!NameID) NameID = 0;
    else DeNameID = parseInt(base64ToString(NameID));
    if (!MasterNameID) MasterNameID = 0;
    else DeMasterNameID = parseInt(base64ToString(MasterNameID));


    useEffect(() => {
        if (localStoreData) {
            setloginAgencyID(localStoreData?.AgencyID);
            setLoginPinID(localStoreData?.PINID);
        }
    }, [localStoreData]);

    // const handleChange = (event) => {
    //     const { name, value } = event.target;
    //     if (event) {
    //         setValue((prevState) => ({ ...prevState, [name]: value, }));
    //     }
    //     else {
    //         setValue((prevState) => ({ ...prevState, [name]: null, }));
    //     }
    // };

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'SSN') {
            let ele = value.replace(/\D/g, '');
            if (ele.length === 9) {
                const cleaned = ('' + ele).replace(/\D/g, '');
                const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
                if (match) {
                    setValue((prevState) => ({
                        ...prevState,
                        [name]: match[1] + '-' + match[2] + '-' + match[3],
                    }));
                }
            } else {
                ele = value.split('-').join('').replace(/\D/g, '');
                setValue((prevState) => ({
                    ...prevState,
                    [name]: ele,
                }));
            }
        } else {
            if (event) {
                setValue((prevState) => ({ ...prevState, [name]: value }));
            } else {
                setValue((prevState) => ({ ...prevState, [name]: null }));
            }
        }
    };

    const ChangeDropDown = (e, name) => {
        if (e) {
            setValue({
                ...value,
                [name]: e.value
            })
            // setPossessionID(e.value);
        } else {
            setValue({
                ...value,
                [name]: null
            });
            // setPossessionID('');
        }
    };

    useEffect(() => {
        if (loginAgencyID) {

            get_Name_Drp_Data(loginAgencyID)
        }


    }, [loginAgencyID])

    const get_Name_Drp_Data = (loginAgencyID) => {
        const val = { AgencyID: loginAgencyID, }
        fetchPostData('MasterName/GetNameDropDown', val).then((data) => {
            if (data) {

                setEthinicityDrpData(Comman_changeArrayFormat(data[0]?.Ethnicity, 'EthnicityID', 'Description'));
                setSexIdDrp(Comman_changeArrayFormat(data[0]?.Gender, 'SexCodeID', 'Description'));

                setRaceIdDrp(Comman_changeArrayFormat(data[0]?.Race, 'RaceTypeID', 'Description'));

            } else {
                setEthinicityDrpData([]); setSexIdDrp([]); setRaceIdDrp([]);

            }
        })
    };



    const getNameSearch = async (LastName, FirstName, MiddleName, DateOfBirth, SSN, Gender, type) => {
        if (LastName || DateOfBirth || FirstName || MiddleName || SSN || Gender) {
            fetchPostData("MasterName/Search_Name", {
                "NameTypeID": "", "LastName": LastName, "FirstName": FirstName || null, "MiddleName": MiddleName || null, "SSN": SSN || null,
                'AgencyID': loginAgencyID || null, "NameIDNumber": "", "NameReasonCodeID": "", "SuffixID": "",
                "DateOfBirthFrom": '', "DateOfBirthTo": "", "SexID": Gender, "RaceID": "", "EthnicityID": "", "HairColorID": "",
                "EyeColorID": "", "WeightFrom": "", "WeightTo": "", "SMTTypeID": "", "SMTLocationID": "", "SMT_Description": "", "IncidentNumber": "", "IncidentNumberTo": "",
                "ReportedDate": "", "ReportedDateTo": "", "DateOfBirth": DateOfBirth, "HeightFrom": "", "HeightTo": ""
            }).then((data) => {
                if (data.length > 0) {
                    const [{ MasterNameID, NameIDNumber }] = data;
                    setNameSearchValue(data);
                    setLogData([]);
                } else {
                    setNameSearchValue([]);
                    if (type) toastifyError('No Name Available');
                }
            });
        } else {
            toastifyError('Required')
        }
    };


    const get_LogData = (MasterNameID) => {
        const val2 = { 'NameID': 0, 'MasterNameID': MasterNameID, 'IsMaster': true }
        fetchPostData("TransactionLog/GetData_TransactionLog", val2).then((res) => {
            if (res) {
                setLogData(res);
            } else {

            }
        })
    }

    const get_DeletedData = () => {
        const val2 = { 'NameID': nameId, 'MasterNameID': 0, 'DeletedByUserFK': loginPinID, "Notes": notes };

        fetchPostData("MasterName/Delete_Name", val2).then((res) => {
            if (res) {
                if (res.data) {
                    try {
                        const parsedData = JSON.parse(res.data);
                        if (parsedData && parsedData.length > 0 && parsedData[0].Message) {
                            const deleteMessage = parsedData[0].Message;
                            toastifySuccess(deleteMessage);
                            get_LogData(NameID);
                            setNameID('');
                        } else {

                        }
                    } catch (error) {

                    }
                } else if (Array.isArray(res) && res.length > 0 && res[0].Message) {
                    const deleteMessage = res[0].Message;
                    toastifySuccess(deleteMessage);
                    setNameID('');
                } else {

                }
                get_LogData(masterNameID);
            } else {
                setLogData([]);
            }
        }).catch(error => {

        });

    };

    const get_DeletedDatas = () => {
        const val2 = { 'NameID': 0, 'MasterNameID': masterNameID, 'DeletedByUserFK': loginPinID, "Notes": notes };
        fetchPostData("MasterName/Delete_Name", val2).then((res) => {
            if (res) {
                if (res.data) {
                    try {
                        const parsedData = JSON.parse(res.data);
                        if (parsedData && parsedData.length > 0 && parsedData[0].Message) {
                            const deleteMessage = parsedData[0].Message;
                            toastifySuccess(deleteMessage);
                            // getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                            get_LogData(NameID);
                            setNameID('');
                        } else {

                        }
                    } catch (error) {

                    }
                } else if (Array.isArray(res) && res.length > 0 && res[0].Message) {
                    const deleteMessage = res[0].Message;
                    getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true);
                    toastifySuccess(deleteMessage);
                    setNameID('');
                } else {

                }
                get_LogData(masterNameID);
            } else {
                setLogData([]);
            }
        }).catch(error => {

        });

    };

    const handleRowSelected = (selectedRows) => {
        if (selectedRows.selectedRows.length > 0) {
            const selectedRow = selectedRows.selectedRows[0];
            setSelectedRow(selectedRow);
            setMasterNameID(selectedRow.MasterNameID);
            get_LogData(selectedRow.MasterNameID);
            setStatesChangeStatus(true);
        } else {
            setSelectedRow(null);
            setMasterNameID(null);
            setLogData([]);
            get_LogData(''); setStatesChangeStatus(false);

        }
    };


    const handleRowDeleted = (selectedRows) => {
        const selectedRow = selectedRows.selectedRows[0];
        if (selectedRows.selectedRows.length > 0) {
            if (selectedRows.selectedCount === logData.length) {
                setShowModal(true)
                setInitialExpungeDone('');
            }
            setStatesChangeStatus(true);
            setSelectedRow(selectedRow);
            setNameID(selectedRow.NameID)
        }
        else {
            setSelectedRow(null);
            setNameID(null);
            setStatesChangeStatus(false);
        }

    };


    const reset = () => {
        setValue({
            ...value,
            'LastName': '', 'FirstName': '', 'MiddleName': '', 'SSN': '', 'DateOfBirth': '', 'SexID': '', 'RaceID': '', 'EthnicityID': '',
        });
        setdateOfbirth("");
        setNameSearchValue([]);
        setLogData([]);
        setShowModal('');
        setSelectedRow('');
        setInitialExpungeDone('');
        setNotes('');
        setStatesChangeStatus(false);
    }


    const columns = [
        {
            name: 'Name Number',
            selector: (row) => row.NameIDNumber,
            sortable: true
        },
        {
            name: 'Last Name',
            selector: (row) => row.LastName,
            sortable: true
        },
        {
            name: 'First Name',
            selector: (row) => row.FirstName,
            sortable: true
        },
        {
            name: 'Middle Name',
            selector: (row) => row.MiddleName,
            sortable: true
        },
        {
            name: 'Gender',
            selector: (row) => row.Gender_Description,
            sortable: true
        },
        {
            name: 'DOB',
            selector: (row) => row.DateOfBirth ? getShowingWithOutTime(row.DateOfBirth) : " ",

            sortable: true
        },
        {
            name: 'Race',
            selector: (row) => row.Race_Description,
            sortable: true
        },
        {
            name: 'SSN',
            selector: (row) => row.SSN,
            sortable: true
        },

    ]
    const columns1 = [
        {
            name: 'Name Number',
            selector: (row) => row.NameNumber,
            sortable: true
        },
        {
            name: 'Transaction Name',
            selector: (row) => row.TransactionName,
            sortable: true
        },
        {
            name: 'Transaction Number',
            selector: (row) => row.TransactionNumber,
            sortable: true
        },
        {
            name: 'Date/Time',
            selector: (row) => getShowingDateText(row.ReportedDate),
            sortable: true
        },

    ]



    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const handleExpungeClick = () => {
        if (!initialExpungeDone) {
            if (!notes.trim()) {
                toastifyError("Notes required");
                return;
            }
            if (nameId) { get_DeletedData(); setShowModal(true) }
            setInitialExpungeDone(true); setNotes('');
        } else {
            // setShowModal(true);
        }
    };

    const handleConfirmExpunge = () => {
        get_DeletedDatas();
        setShowModal(false);
        setInitialExpungeDone(false);
        setClearSelectedRows(!clearSelectedRows);
    };

    useEffect(() => {
        document.addEventListener('load', function () {
            document.getElementById('#myModal').modal('showModal');
        });
    }, [])

    const onDashboardClose = () => {
        navigate('/dashboard-page');
    }

    // console.log(value, 'value');

    return (
        <>
            <div className="section-body view_page_design pt-1">
                <div className="row clearfix" >
                    <div className="col-12 col-sm-12">
                        <div className="card Agency  name-card">
                            <div className="card-body">
                                <div className="col-12 col-md-12 col-lg-12 ">
                                    <div className="row " style={{ marginTop: '-10px' }}>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label  '>Last Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field mt-1">
                                            <input type="text" name='LastName' autoComplete='off' value={value.LastName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label '>First Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='FirstName' autoComplete='off' value={value.FirstName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2 px-0">
                                            <label htmlFor="" className='new-label px-0'>Middle&nbsp;Name</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='MiddleName' autoComplete='off' value={value.MiddleName} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Gender</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                                name='SexID'
                                                value={sexIdDrp?.filter((obj) => obj.value === value?.SexID)}
                                                options={sexIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'SexID')}
                                                isClearable
                                                placeholder="Select..."
                                            // isDisabled={nameTypeCode === "B" ? true : false}
                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2">
                                            <label htmlFor="" className='new-label'>DOB</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 ">
                                            <DatePicker
                                                id='DateOfBirth'
                                                name='DateOfBirth'
                                                // ref={startRef}
                                                // onKeyDown={onKeyDown}
                                                onChange={(date) => {
                                                    setdateOfbirth(date); setValue({ ...value, ['DateOfBirth']: date ? getShowingMonthDateYear(date) : null, });

                                                }}
                                                dateFormat="MM/dd/yyyy"
                                                isClearable={value.DateOfBirth ? true : false}
                                                selected={dateOfbirth}
                                                placeholderText={value.DateOfBirth ? value.DateOfBirth : 'Select...'}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete='Off'
                                                // maxDate={new Date()}
                                                // maxDate={new Date(incReportedDate)}
                                                // maxDate={MstPage === "MST-Name-Dash" ? new Date() : new Date(incReportedDate)}
                                                // disabled={nameTypeCode === "B" ? true : false}
                                                // className={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? 'readonlyColor' : '' || isAdult ? 'requiredColor' : ''}
                                                // readOnly={(nameTypeCode === "B" || value?.IsUnknown === 'true' || value?.IsUnknown === true) ? true : false}
                                                maxDate={new Date()}

                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1  mt-2">
                                            <label htmlFor="" className='new-label '>SSN</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3  text-field  mt-1">
                                            <input type="text" name='SSN' autoComplete='off' value={value.SSN} maxLength={9} onChange={(e) => { handleChange(e) }} />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Race</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                name='RaceID'
                                                value={raceIdDrp?.filter((obj) => obj.value === value?.RaceID)}
                                                options={raceIdDrp}
                                                onChange={(e) => ChangeDropDown(e, 'RaceID')}
                                                isClearable
                                                placeholder="Select..."
                                            // isDisabled={nameTypeCode === "B" ? true : false}
                                            // styles={isAdult ? colourStyles : customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="col-2 col-md-2 col-lg-1 mt-2 ">
                                            <label htmlFor="" className='new-label'>Ethnicity</label>
                                        </div>
                                        <div className="col-4 col-md-4 col-lg-3 mt-1">
                                            <Select
                                                name='EthnicityID'
                                                value={ethinicityDrpData?.filter((obj) => obj.value === value?.EthnicityID)}
                                                options={ethinicityDrpData}
                                                onChange={(e) => ChangeDropDown(e, 'EthnicityID')}
                                                isClearable
                                                placeholder="Select..."
                                            // styles={customStylesWithOutColor}
                                            />
                                        </div>
                                        <div className="btn-box col-4  text-right   mb-1 mt-3" >
                                            <button type="button" className="btn btn-sm btn-success mr-1" onClick={() => getNameSearch(value.LastName, value.FirstName, value.MiddleName, value.DateOfBirth, value.SSN, value.SexID, true)}>Search</button>
                                            <button type="button" data-dismiss="modal" className="btn btn-sm btn-success mr-1" onClick={onDashboardClose} >Close</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-1">
                                    <DataTable
                                        dense
                                        columns={columns}
                                        data={nameSearchValue}
                                        pagination
                                        highlightOnHover
                                        fixedHeaderScrollHeight='120px'
                                        fixedHeader
                                        persistTableHead={true}
                                        customStyles={tableCustomStyles}
                                        selectableRows="single"
                                        selectableRowsHighlight
                                        onSelectedRowsChange={handleRowSelected}


                                    />
                                </div>
                                <fieldset className='mt-2 mb-1'>
                                    <legend>Select Event To be Deleted</legend>
                                    <div className="col-12 mt-3 px-0">
                                        <DataTable
                                            dense
                                            columns={columns1}
                                            data={logData}
                                            pagination
                                            highlightOnHover
                                            fixedHeaderScrollHeight='100px'
                                            fixedHeader
                                            persistTableHead={true}
                                            customStyles={tableCustomStyles}
                                            selectableRows
                                            selectableRowsHighlight
                                            onSelectedRowsChange={handleRowDeleted}
                                            clearSelectedRows={clearSelectedRows}
                                        />
                                        {/* <DataTable
                                    dense
                                    columns={columns}
                                    data={nameSearchValue}
                                    pagination
                                    selectableRowsHighlight
                                    highlightOnHover
                                /> */}
                                    </div>
                                </fieldset>
                                <div className="col-12  bt">
                                    <div className="row">
                                        <div className="col-2 col-md-2 col-lg-1 mt-3">
                                            <label htmlFor="" className='label-name '>Notes</label>
                                        </div>
                                        <div className="col-10 col-md-10 col-lg-11 text-field mt-1" >
                                            <textarea name='Notes' id="Notes" value={notes} onChange={(e) => { setNotes(e.target.value) }} cols="30" rows='1' className="form-control pt-2 pb-2 requiredColor" ></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 field-button" style={{ position: 'absolute', bottom: '5px', textAlign: 'right' }} >
                                    <button type="button" className="btn btn-sm btn-success mr-1" disabled={!statesChangeStatus} onClick={handleExpungeClick}
                                        {...(showModal && nameId ? { 'data-toggle': 'modal', 'data-target': '#myModal' } : {})}
                                    >Expunge</button>
                                    <button type="button" data-toggle="modal" data-target="#myModal" onClick={() => { reset(); }} className="btn btn-sm btn-success mr-4">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            <ExpungeConfirmModel showModal={showModal}
                setShowModal={setShowModal}
                onConfirm={handleConfirmExpunge} />


        </>
    )
}

export default Expunge