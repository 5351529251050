import React, { createContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import MonitorServices from '../../CADServices/APIs/monitor';

export const IncidentContext = createContext();

const IncidentData = ({ children }) => {
    const [loginAgencyID, setLoginAgencyID] = useState();
    const localStoreData = useSelector((state) => state.Agency.localStoreData);
    const [incidentData, setIncidentData] = useState([]);
    const [resourceData, setResourceData] = useState([]);

    const getIncidentListKey = `/CAD/Monitor/MonitorIncidentsView`;
    const { data: incidentList, isSuccess: isFetchIncidentList, refetch: incidentRefetch } = useQuery(
        [getIncidentListKey, { AgencyID: loginAgencyID }],
        MonitorServices.getIncidentsView,
        {
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                if (res?.data?.Data?.length === 0) {
                    return;
                } else {
                    try {
                        const parsedData = JSON.parse(res?.data?.data);
                        const data = parsedData?.Table;
                        setIncidentData(data);
                    } catch (error) {
                        console.error("Error parsing name:", error);
                    }
                }
            },
            onError: (error) => {
                if (error?.response?.data?.Code === 400) {
                    console.error("No Data Available", error?.response?.data?.Message);

                } else {
                    console.error("An error occurred:", error);
                }
            }
        }
    );

    const getResourceListKey = `CAD/Monitor/MonitorResourceView`;
    const { data: resourceList, isSuccess: isFetchResourceList, refetch: resourceRefetch } = useQuery(
        [getResourceListKey, { AgencyID: loginAgencyID }],
        MonitorServices.getResourceView,
        {
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                if (res?.data?.Data?.length === 0) {
                    return;
                } else {
                    try {
                        const parsedData = JSON.parse(res?.data?.data);
                        const data = parsedData?.Table;
                        setResourceData(data);
                    } catch (error) {
                        console.error("Error parsing name:", error);
                    }
                }
            }, 
            onError: (error) => {
                if (error?.response?.data?.Code === 400) {
                    console.error("No Data Available", error?.response?.data?.Message);

                } else {
                    console.error("An error occurred:", error);
                }
            }
        }
    );

    useEffect(() => {
        if (localStoreData) {
            setLoginAgencyID(localStoreData?.AgencyID);
        }
    }, [localStoreData]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            incidentRefetch();
            resourceRefetch();
        }, 15000);

        return () => clearInterval(intervalId);
    }, [incidentRefetch, resourceRefetch]);

    return (
        <IncidentContext.Provider
            value={{
                incidentData,
                isFetchIncidentList,
                incidentRefetch,
                resourceData,
                isFetchResourceList,
                resourceRefetch,
            }}
        >
            {children}
        </IncidentContext.Provider>
    );
};

export default IncidentData;
