import React, { useContext, useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { CadDashboardTableCustomStyles, getShowingYearMonthDate, stringToBase64 } from '../../../Components/Common/Utility';
import DateTimeCounter from '../../Common/DateTimeCounter';
import { useNavigate } from 'react-router-dom';
import { IncidentContext } from '../../../CADContext/Incident';

const columns = [
  {
    name: 'Officer 1',
    selector: (row) => row.Officer1,
    sortable: true,
  },
  {
    name: 'Officer 2',
    selector: (row) => row.Officer2,
    sortable: true,
  },
  {
    name: 'Resource #',
    selector: (row) => row.ResourceNumber,
    sortable: true,
  },
  {
    name: 'Agency Code',
    selector: (row) => row.AgencyCode,
    sortable: true,
  },
  {
    name: 'Incident #',
    selector: (row) => row.CADIncidentNumber,
    sortable: true,
  },
  {
    name: 'Status',
    selector: (row) => row.Status,
    sortable: true,
  },
  {
    name: 'Status Date & Time',
    selector: (row) => row.StatusDT ? getShowingYearMonthDate(row.StatusDT) : "",
    sortable: true,
    width: "12%"
  },
  {
    name: 'Zone',
    selector: (row) => row.ZoneCode,
    sortable: true,
  },
  {
    name: 'E Time',
    selector: (row) => row.StatusDT ? <DateTimeCounter data={row.StatusDT} /> : "",
    sortable: true,
  },
  {
    name: 'Incident Date & Time',
    selector: (row) => row.IncidentDtTm,
    sortable: true,
    width: "12%"
  },
  {
    name: 'Total Time',
    selector: (row) => row.IncidentDtTm ? <DateTimeCounter data={row.IncidentDtTm} /> : "",
    sortable: true,
  },
];
const ResourcesTableSection = () => {
  const { resourceData } = useContext(IncidentContext);
  const [editValue, setEditValue] = useState("");
  const [incidentID, setIncidentID] = useState("");
  const [incidentNumber, setIncidentNumber] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/cad/dashboard-page?IncId=${stringToBase64(incidentID)}&IncNo=${incidentNumber}&IncSta=true`);
  }, [incidentID, incidentNumber]);

  const conditionalRowStyles = [
    {
      when: row => row === editValue,
      style: {
        backgroundColor: '#001f3fbd',
        color: 'white',
        cursor: 'pointer',
      },
    }
  ];

  return (
    <>
      <div className='table-container'>
        <div className='header-Container d-flex justify-content-between align-items-center'>
          <span>{'Resources View'}</span>
          <div className='d-flex' style={{fontSize: "12px", }}>
            <div className='d-flex align-content-center justify-content-start table-header-status' style={{ color: "white", }}>
              <div className='d-flex'>
                <span>Active</span>
                <span>5</span>
              </div>
              <div className='d-flex'>
                <span>Available</span>
                <span>0</span>
              </div>
              <div className='d-flex'>
                <span>Unavailable</span>
                <span>0</span>
              </div>
            </div>
            <div className='d-flex align-content-center justify-content-end table-header-buttons'>
              <button>Active</button>
              <button>Available</button>
              <button>
                <FontAwesomeIcon icon={faFilter} style={{ marginRight: '8px', opacity: 0.7 }} />
                Filters
              </button>
            </div>
          </div>
        </div>
        <DataTable
          dense
          columns={columns}
          data={resourceData}
          onRowClicked={(row) => {
            setIncidentID(row?.IncidentID)
            setIncidentNumber(row?.CADIncidentNumber)
            setEditValue(row);
          }}
          customStyles={CadDashboardTableCustomStyles}
          conditionalRowStyles={conditionalRowStyles}
          pagination
          responsive
          striped
          highlightOnHover
          fixedHeader
          persistTableHead={true}
        />

      </div>
    </>
  );
};

export default ResourcesTableSection;
