import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { Filter, SendIcon } from '../../../Components/Filter/Filter';
import { tableCustomStyles } from '../../../Components/Common/Utility';
import DataTable from 'react-data-table-component';
import '../section.css';
import useObjState from '../../../CADHook/useObjState';
import { toastifySuccess } from '../../../Components/Common/AlertMsg';
import MasterTableListServices from "../../../CADServices/APIs/masterTableList";
import { useQuery } from 'react-query';
import CADConfirmModal from '../../Common/CADConfirmModal';
import { isEmpty } from '../../../CADUtils/functions/common';
import { useSelector } from 'react-redux';

const ResourceTypeSection = () => {
  const localStoreData = useSelector((state) => state.Agency.localStoreData);
  const [filterTypeIdOption, setFilterTypeIdOption] = useState('Contains');
  const [filterTypeDescOption, setFilterTypeDescOption] = useState('Contains');
  const [pageStatus, setPageStatus] = useState("1");
  const [filterListData, setFilterListData] = useState([]);
  const [listData, setListData] = useState([]);
  const [searchValue1, setSearchValue1] = useState('');
  const [searchValue2, setSearchValue2] = useState('');
  const [confirmType, setConfirmType] = useState('');
  const [activeInactiveData, setActiveInactiveData] = useState({})
  const [showModal, setShowModal] = useState(false);
  const [isActive, setIsActive] = useState('');
  const [loginPinID, setLoginPinID] = useState('');

  const [
    resourceTypeState,
    setResourceTypeState,
    handleResourceTypeState,
    clearResourceTypeState,
  ] = useObjState({
    id: "",
    typeCode: "",
    description: "",
    law: false,
    fire: false,
    ems: false,
    other: false,
    isActive: true
  });

  const [
    errorResourceTypeState,
    _setErrorResourceTypeState,
    handleErrorResourceTypeState,
    clearStateResourceTypeState,
  ] = useObjState({
    typeCode: false,
    description: false,
    agencyType: false,
  });

  const columns = [
    {
      name: 'Resource Type Code',
      selector: row => row?.ResourceTypeCode,
      sortable: true,
    },
    {
      name: 'Description',
      selector: row => row?.description,
      sortable: true,
      width: "60%",
    },
    {
      name: 'Agency Type',
      selector: row => {
        let result = [];
        if (row?.law === 1) result.push("L");
        if (row?.fire === 1) result.push("F");
        if (row?.emergencymedicalservice === 1) result.push("E");
        if (row?.Other === 1) result.push("O");
        return result.join(", ") || "";
      },
      sortable: true,
    },
    {
      name:
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {'Status'}
        </div>,
      cell: (row, index) =>
        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <span
            className="btn btn-sm text-white px-1 py-0 mr-1"
            style={{ background: "#ddd", cursor: "pointer" }}
          >
            {row?.isActive ? (
              <i className="fa fa-toggle-on" style={{ color: "green" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(0); setConfirmType("InActive") }}></i>
            ) : (
              <i className="fa fa-toggle-off" style={{ color: "red" }} aria-hidden="true" onClick={(e) => { setShowModal(true); setActiveInactiveData(row); setIsActive(1); setConfirmType("Active"); }}></i>
            )}
          </span>
        </div>,
      width: "70px",
    },
  ];

  useEffect(() => {
    if (localStoreData) {
      setLoginPinID(localStoreData?.PINID);
    }
  }, [localStoreData]);

  const GetResourceTypeKey = `/CAD/MasterResourceType/GetResourceType`;
  const { data: resourceTypeData, isSuccess: isFetchResourceType, refetch, isError: isNoData } = useQuery(
    [GetResourceTypeKey, { Action: "GET", IsActive: parseInt(pageStatus) },],
    MasterTableListServices.getResourceType,
    {
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );

  useEffect(() => {
    if (isFetchResourceType && resourceTypeData) {
      setFilterListData(resourceTypeData?.data?.data?.Table)
      setListData(resourceTypeData?.data?.data?.Table)
    } else {
      setFilterListData([])
      setListData([])
    }
  }, [resourceTypeData, isFetchResourceType])

  function handelSetEditData(data) {
    setResourceTypeState({
      id: data?.ResourceTypeID || "",
      typeCode: data?.ResourceTypeCode || "",
      description: data?.description || "",
      law: data?.law === 1 ? true : false,
      fire: data?.fire === 1 ? true : false,
      ems: data?.emergencymedicalservice === 1 ? true : false,
      other: data?.Other === 1 ? true : false,
      isActive: data?.isActive
    })
  }

  function handelCancel() {
    clearStateResourceTypeState();
    clearResourceTypeState();
  }

  async function handelActiveInactive() {
    const data = {
      ResourceTypeID: activeInactiveData?.ResourceTypeID,
      DeletedByUserFK: loginPinID,
      IsActive: isActive,
    }
    const response = await MasterTableListServices.changeStatusResourceType(data);
    if (response?.status === 200) {
      const data = JSON.parse(response?.data?.data)?.Table?.[0];
      toastifySuccess(data?.Message);
      refetch();
    }
    setShowModal(false);
  }

  const validateResourceTypeForm = () => {
    let isError = false;
    const keys = Object.keys(errorResourceTypeState);
    keys.map((field) => {
      if (
        field === "typeCode" &&
        isEmpty(resourceTypeState[field])) {
        handleErrorResourceTypeState(field, true);
        isError = true;
      } else if (field === "description" && isEmpty(resourceTypeState[field])) {
        handleErrorResourceTypeState(field, true);
        isError = true;
      } else if (field === "agencyType" && (resourceTypeState.law === false && resourceTypeState.fire === false && resourceTypeState.ems === false && resourceTypeState.other === false)) {
        handleErrorResourceTypeState(field, true);
        isError = true;
      } else {
        handleErrorResourceTypeState(field, false);
      }
    });
    return !isError;
  };

  async function handleSave() {
    if (!validateResourceTypeForm()) return
    const isUpdate = resourceTypeState?.id ? true : false;
    const data = {
      Action: isUpdate ? "UPDATE" : "ADD",
      ResourceTypeID: resourceTypeState?.id || undefined,
      ResourceTypeCode: resourceTypeState?.typeCode,
      description: resourceTypeState?.description,
      law: resourceTypeState?.law ? 1 : 0,
      fire: resourceTypeState?.fire ? 1 : 0,
      emergencymedicalservice: resourceTypeState?.ems ? 1 : 0,
      Other: resourceTypeState?.other ? 1 : 0,
      isActive: resourceTypeState?.isActive ? 1 : 0
    };
    try {
      const response = await MasterTableListServices.insertResourceType(data);
      if (response?.status === 200) {
        toastifySuccess(isUpdate ? "Data Updated Successfully" : "Data Saved Successfully");
        handelCancel();
        refetch();
      }
    } catch (error) {
      console.error("Error saving resource type:", error);
    }
  }

  const handleSpecialKeyDown = (e) => {
    const isAlphanumeric = e.key.length === 1 && e.key.match(/[a-zA-Z0-9]/);
    const isBackspace = e.key === "Backspace";

    if (!isAlphanumeric && !isBackspace) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className='utilities-tab-content-main-container'>
        <div className='utilities-tab-content-form-container'>
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12 incident-tab ">
              <ul className="nav nav-tabs mb-1 pl-2 " id="myTab" role="tablist">
                <span className={`nav-item ${pageStatus === '1' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("1"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '1' ? 'Red' : '' }}>Active</span>
                <span className={`nav-item ${pageStatus === '0' ? 'active' : ''}`} onKeyDown={''} onClick={() => { setPageStatus("0"); setSearchValue1(""); setSearchValue2(""); }} id="home-tab" data-bs-toggle="tab" data-bs-target="#" type="button" role="tab" aria-controls="home" aria-selected="true" style={{ color: pageStatus === '0' ? 'Red' : '' }}>InActive</span>
              </ul>
            </div>
            {
              pageStatus === '1' ?
                <>
                  <div className='utilities-tab-content-form-main'>
                    {/* line 1 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Resource Type Code{errorResourceTypeState.typeCode && isEmpty(resourceTypeState.typeCode) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Code"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-2 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Resource Type Code'
                          maxLength={10}
                          onKeyDown={handleSpecialKeyDown}
                          value={resourceTypeState.typeCode}
                          onChange={(e) => { handleResourceTypeState("typeCode", e.target.value) }}
                        />
                      </div>
                      <div className="col-1 d-flex align-self-center justify-content-end">
                        <label for="" className="tab-form-label">
                          Description{errorResourceTypeState.description && isEmpty(resourceTypeState.description) && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Enter Description"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-4 d-flex align-self-center justify-content-end">
                        <input
                          type="text"
                          className="form-control py-1 new-input requiredColor"
                          placeholder='Description'
                          value={resourceTypeState.description}
                          onChange={(e) => { handleResourceTypeState("description", e.target.value) }}
                        />
                      </div>
                    </div>

                    {/* line 2 */}
                    <div className="row">
                      <div className="col-2 d-flex align-self-start justify-content-end">
                        <label for="" className="tab-form-label" style={{ marginTop: "10px" }}>
                          Required Agency Types{errorResourceTypeState.agencyType && resourceTypeState.law === false && resourceTypeState.fire === false && resourceTypeState.ems === false && resourceTypeState.other === false && (
                            <p style={{ color: 'red', fontSize: '11px', margin: '0px', padding: '0px' }}>{"Select Agency Type"}</p>
                          )}
                        </label>
                      </div>
                      <div className="col-7 d-flex align-self-center justify-content-end">
                        <div className='agency-types-checkbox-container'>
                          {/* L : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.law} onChange={(e) => { handleResourceTypeState("law", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>L</span>
                              <span>|</span>
                              <span>Law</span>
                            </div>
                          </div>
                          {/* F : Fire */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.fire} onChange={(e) => { handleResourceTypeState("fire", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>F</span>
                              <span>|</span>
                              <span>Fire</span>
                            </div>
                          </div>
                          {/* E : Emergency Medical Service */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.ems} onChange={(e) => { handleResourceTypeState("ems", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>E</span>
                              <span>|</span>
                              <span>Emergency Medical Service </span>
                            </div>
                          </div>
                          {/* O : Law */}
                          <div className="agency-checkbox-item">
                            <input type="checkbox" checked={resourceTypeState.other} onChange={(e) => { handleResourceTypeState("other", e.target.checked) }} />
                            <div className="agency-checkbox-text-container">
                              <span>O</span>
                              <span>|</span>
                              <span>other</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                :
                <>
                </>
            }
          </div>

        </div>

        <div className='utilities-tab-content-table-container'>
          <div className="row">
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                value={searchValue1}
                onChange={(e) => {
                  setSearchValue1(e.target.value);
                  const result = Filter(listData, e.target.value, searchValue2, filterTypeIdOption, 'ResourceTypeCode', 'description')
                  setFilterListData(result)
                }}
                placeholder='Search By Code...'
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '2', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeIdOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeIdOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className="col-5 d-flex align-self-center justify-content-end">
              <input
                type="text"
                className="form-control py-1 new-input"
                placeholder='Search By Description...'
                value={searchValue2}
                onChange={(e) => {
                  setSearchValue2(e.target.value);
                  const result = Filter(listData, searchValue1, searchValue2, filterTypeDescOption, 'ResourceTypeCode', 'description')
                  setFilterListData(result)
                }}
              />
            </div>
            <div className="col-1 d-flex align-self-center justify-content-end" style={{ zIndex: '1', }}>
              <Dropdown className='w-100'>
                <Dropdown.Toggle id="dropdown-basic" className='cad-sort-dropdown'>
                  <img src={SendIcon(filterTypeDescOption)} alt="" className='filter-icon mr-1' />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Contains')}>Contains</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is equal to')}>is equal to</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('is not equal to')}>is not equal to </Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('Starts With')}>Starts With</Dropdown.Item>
                  <Dropdown.Item onClick={() => setFilterTypeDescOption('End with')}>End with</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {isFetchResourceType &&
            <div className="table-responsive">
              <DataTable
                dense
                columns={columns}
                data={filterListData}
                customStyles={tableCustomStyles}
                pagination
                fixedHeaderScrollHeight="360px"
                responsive
                striped
                highlightOnHover
                noDataComponent={isNoData ? "There are no data to display" : 'There are no data to display'}
                fixedHeader
                onRowClicked={(row) => {
                  handelSetEditData(row);
                }}
              // fixedHeaderScrollHeight="200px" 
              />
            </div>}
          {parseInt(pageStatus) === 1 &&
            <div className="utilities-tab-content-button-container" >
              <button type="button" className="btn btn-sm btn-success" onClick={() => { handelCancel() }}>New</button>
              <button type="button" className="btn btn-sm btn-success" onClick={() => { handleSave() }}>{!!resourceTypeState?.id ? "Update" : "Save"}</button>
            </div>}
        </div>
      </div>
      <CADConfirmModal showModal={showModal} setShowModal={setShowModal} handleConfirm={handelActiveInactive} confirmType={confirmType} />
    </>
  );
};

export default ResourceTypeSection;

